import { BackendServicesClient } from "~/context/BackendServicesContext";

export interface AIAdImage {
  imageFileId: string;
  readSignedUrl: string;
  score: number;
}

export interface AIAdDescription {
  title?: string;
  images?: AIAdImage[];
  productDescription?: string;
}

export async function getAIAdDescription(
  client: BackendServicesClient,
  url: string,
  { signal }: { signal?: AbortSignal } = {}
): Promise<AIAdDescription> {
  try {
    const result = await client.post<AIAdDescription>(
      `ugcAds/v1/generateInfo`,
      {
        url,
        configuration: {
          productDescription: {
            enabled: true,
          },
          title: {
            enabled: true,
          },
          images: {
            enabled: true,
          },
          medias: {
            enabled: true,
          },
        },
      },
      { signal }
    );
    return result.data;
  } catch (error) {
    if (!signal?.aborted) {
      console.error(error);
    }
    throw error;
  }
}
