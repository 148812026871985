import { BackendServicesClient } from "~/context/BackendServicesContext";

interface AIAdsRefreshScriptResponse {
  transcript: string;
}

export async function refreshAIAdScripts(
  client: BackendServicesClient,
  adData: {
    title: string;
    productName: string;
    description: string;
    targetAudience: string;
    adHookCategoryId: string;
    languageCode: string;
  },
  options: { signal?: AbortSignal } = {}
): Promise<string> {
  try {
    const result = await client.post<AIAdsRefreshScriptResponse>(
      `ugcAds/v1/generateInfo`,
      {
        configuration: {
          transcript: {
            enabled: true,
            languageCode: adData.languageCode,
          },
        },
        metadata: {
          title: adData.title,
          productName: adData.productName,
          productDescription: adData.description,
          targetAudience: adData.targetAudience,
          adHookCategoryId: adData.adHookCategoryId,
        },
      },
      { signal: options.signal }
    );

    return result.data.transcript;
  } catch (error) {
    if (!options.signal?.aborted) {
      console.error(error);
    }
    throw error;
  }
}
