import useLatest from "@react-hook/latest";
import { AxiosError } from "axios";
import { t } from "i18next";
import { useCallback, useEffect, useRef } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { AIAdsScript } from "~/modules/project/services/UGCAds/getAIAdScripts";
import { refreshAIAdScripts } from "~/modules/project/services/UGCAds/refreshAIAdScript";

export interface AIAdsScriptsRefresherProps {
  /**
   * Callback to be called when a script is refreshed.
   * @param {number} index - The index of the script to refresh.
   * @param {AIAdsScript} script - The refreshed script.
   */
  onFetch: (index: number, script: AIAdsScript) => void;
  /**
   * Callback to be called when an error occurs while refreshing a script.
   * @param {number} index - The index of the script that was being refreshed.
   * @param {AxiosError} error - The error that occurred.
   */
  onError: (index: number, error: AxiosError) => void;
  /**
   * Callback to be called when the refresh is cancelled.
   * @param {number} index - The index of the refreshed script.
   * @remarks Will not be called if a request is cancelled due to a new request being made.
   * @remarks Will not be called if a request is cancelled due to the component unmounting.
   */
  onCancel: (index: number) => void;
}

export function useAIAdsScriptsRefresher(callbacks: AIAdsScriptsRefresherProps) {
  const client = useBackendServicesClient();
  const abortControllers = useRef<(AbortController | undefined)[]>([]);

  const onFetch = useLatest(callbacks.onFetch);
  const onError = useLatest(callbacks.onError);
  const onCancel = useLatest(callbacks.onCancel);

  const refreshScript = useCallback(
    (
      index: number,
      script: AIAdsScript,
      adData: {
        title: string;
        productName: string;
        description: string;
        targetAudience: string;
        languageCode: string;
      }
    ) => {
      const fetchAbortController = new AbortController();
      abortControllers.current[index]?.abort();
      abortControllers.current[index] = fetchAbortController;
      refreshAIAdScripts(
        client,
        {
          ...adData,
          adHookCategoryId: script.adHookCategory.id,
        },
        { signal: fetchAbortController.signal }
      )
        .then((transcript) => {
          onFetch.current(index, { ...script, transcript });
        })
        .catch((error) => {
          if (fetchAbortController.signal.aborted) {
            return;
          }
          const err = new AxiosError(
            error.response.data.message || t("projects:ai-ads.script-step.failed-fetch")
          );
          onError.current(index, err);
          console.error(err);
        })
        .finally(() => {
          if (abortControllers.current[index] === fetchAbortController) {
            abortControllers.current[index] = undefined;
          }
        });
    },
    []
  );

  const cancelRefreshScripts = useCallback(() => {
    for (let index = 0; index < abortControllers.current.length; index++) {
      const controller = abortControllers.current[index];
      if (controller) {
        controller.abort();
        onCancel.current(index);
      }
    }
    abortControllers.current = [];
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      for (const controller of abortControllers.current) {
        controller?.abort();
      }
    };
  }, []);

  return {
    refreshScript,
    cancelRefreshScripts,
  };
}
