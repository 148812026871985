import { styled } from "~/theme";

export const AIAdsPageIndicatorContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 16,
  width: "100%",
});

export const AIAdsPageIndicatorStep = styled("div", {
  height: 6,
  maxWidth: 89,
  flex: 1,
  borderRadius: 6,
  variants: {
    isActive: {
      true: {
        backgroundColor: "$secondary-niagara",
        boxShadow:
          "0px 0px 24px 4px color-mix(in srgb, $colors$secondary-niagara 40%, transparent);",
      },
      false: {
        backgroundColor: "$grey-800",
      },
    },
  },
});
