import { CaptionTemplatePanelList } from "~/modules/project/components/CaptionTemplatePanelList";
import { styled } from "~/theme";

import { Text } from "../../Text";

export const AIAdsFooter = styled("div", {
  padding: "$8 0",
});

export const AIAdsFooterText = styled(Text, {
  defaultVariants: {
    color: "grey-600",
    variant: "body-3",
  },
});

export const AIAdsFooterLink = styled(Text, {
  defaultVariants: {
    color: "grey-400",
    variant: "body-3",
  },
});

export const AIAdsStyleContents = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 24,
  width: "100%",
  flexGrow: 1,
});

export const AIAdsAvatars = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  justifyContent: "center",
});

export const AIAdsTemplateContainer = styled("div", {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 0,
  minHeight: 0,
});

export const AIAdsTemplateList = styled(CaptionTemplatePanelList, {
  padding: "$4 $12",
});
