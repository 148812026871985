import { Button } from "../../Button";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import { Spinner } from "../../Spinner";

import { AIAdsDialogNavContainer } from "./AIAdsDialogNav.styles";
import { AIAdsDialogNavProps } from "./AIAdsDialogNav.types";

export function AIAdsDialogNav({
  onNext,
  onBack,
  nextLabel,
  isNextDisabled,
  isBackDisabled,
  isLoadingNextStep,
}: AIAdsDialogNavProps) {
  const handleClick = () => {
    if (!isLoadingNextStep) {
      onNext();
    }
  };

  return (
    <AIAdsDialogNavContainer>
      <Button
        onClick={onBack}
        disabled={isBackDisabled || isLoadingNextStep}
        colorScheme="niagara"
        variant="secondary"
      >
        <ArrowLeft />
      </Button>
      <Button
        onClick={handleClick}
        disabled={isNextDisabled}
        colorScheme="niagara"
        variant="primary"
      >
        {isLoadingNextStep && <Spinner theme="white" inline />}
        {!isLoadingNextStep && (
          <>
            {nextLabel}
            <ArrowRight />
          </>
        )}
      </Button>
    </AIAdsDialogNavContainer>
  );
}
