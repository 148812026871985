import { styled } from "~/theme";

import { Text } from "../../Text";

export const AIAdsDialogHeader = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

export const AIAdsDialogSubtitle = styled(Text, {
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  defaultVariants: {
    color: "grey-500",
    variant: "body-1",
  },
});

export const AIAdsDialogFieldTitle = styled(Text, {
  defaultVariants: {
    variant: "body-3-caps",
    color: "grey-600",
  },
});

export const AIAdsDialogField = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 12,
});
