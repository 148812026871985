import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  AI_ADS_FORM_INPUT,
  AI_ADS_FORM_VIEW,
  AI_CREATOR_SCRIPT_CREATED,
  AI_CREATOR_SCRIPT_EDITED,
} from "~/constants/mixpanel.constants";
import { ProjectCreationContext } from "~/context/ProjectCreationContext";
import { ScriptType } from "~/context/ProjectCreationContext/ProjectCreationContext.types";
import { useCallbackOnce, useLatestRef, useOnChangeEffect } from "~/hooks/helpers";
import { useAIAdsInfoFetcher } from "~/hooks/useAIAdsInfoFetcher";
import { useAIAdsScriptsRefresher } from "~/hooks/useAIAdsScriptRefresher";
import { useAIAdsScriptsFetcher } from "~/hooks/useAIAdsScriptsFetcher";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useCreatorHubScriptFetcher } from "~/hooks/useCreatorHubScriptFetcher";
import { useCreators } from "~/hooks/useCreators";
import { useListAvatarsQuery } from "~/hooks/useListAvatarsQuery";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useMediaItems } from "~/hooks/useMediaItems";
import { useAvailableTranscriptLanguages } from "~/modules/project/hooks/useAvailableTranscriptLanguages";
import { useCaptionStyleTemplates } from "~/modules/project/hooks/useCaptionStyleTemplates";
import { useLastUsedLanguages } from "~/modules/project/hooks/useLastUsedLanguages";
import { getSmallestAvatarThumbnail } from "~/modules/project/utils/avatarThumbnail";
import { sanitizeUrl } from "~/utils/url";

import { useToast } from "../Toast";

import {
  AIAdsCreationDialogProps,
  AIAdsCreationStep,
  AIAdsScriptItem,
  OnNextCallback,
  OnNextCallbackParams,
  SelectedScriptIndex,
  MixPanelEventStatusEnum,
  TrackCreatorHubEventFunction,
  GeneratedVideoProjectType,
  TrackCreatorHubEventParams,
  CreatorHubFlowType,
  ScriptSource,
} from "./AIAdsCreationDialog.types";

export interface UseAIAdsCreationDialogResult extends AIAdsCreationDialogProps {
  open: () => void;
  isCreatorHubEnabled: boolean;
}

const STEP_TRACKING_NAMES = {
  url: "url_form",
  info: "product_info",
  script: "script_info",
  avatar: "style_info",
  initial: "initial",
  prompt: "prompt",
  custom: "custom",
} as const;

export const AI_CREATOR_SCRIPT_MAX_LENGTH = 800;
export const AI_ADS_SCRIPT_MAX_LENGTH = 500;

const getInitialStep = (creatorHubEnabled: boolean) => (creatorHubEnabled ? "initial" : "url");
const getProjectType = (creatorHubEnabled: boolean) => (creatorHubEnabled ? "ai_avatar" : "ai_ads");

export function useAIAdsCreationDialog(): UseAIAdsCreationDialogResult {
  const router = useRouter();
  // if we are on the creator hub URL, we force the creator hub to be enabled
  // regardless of flag evaluation so we do not show any flashes
  const forceCreatorHubByUrl = router.asPath.includes("/projects/generate");
  const isCreatorHubEnabled = true;
  const toast = useToast();
  const { lastLanguageCode } = useLastUsedLanguages();
  const { availableLanguages } = useAvailableTranscriptLanguages();
  const [projectType, setProjectType] = useState<GeneratedVideoProjectType>();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<AIAdsCreationStep>();
  const [previousStep, setPreviousStep] = useState<AIAdsCreationStep>();
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);
  const [flowType, setFlowType] = useState<CreatorHubFlowType>();
  // Step 1: URL
  const [url, setUrl] = useState("");
  // Step 2: Info
  const [title, setTitle] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [languageCode, setLanguageCode] = useState(lastLanguageCode);
  const mediaItems = useMediaItems();
  // Step 3: Script
  const [scripts, setScripts] = useState<AIAdsScriptItem[]>([]);
  const [selectedScriptIndex, setSelectedScriptIndex] = useState<SelectedScriptIndex>(0);
  const [customScript, setCustomScript] = useState<string | undefined>();
  // Step 4: Avatar
  const { data: avatars = [] } = useListAvatarsQuery();
  const { data: creators = [] } = useCreators();
  const avatarsToUse = projectType === "ai_avatar" ? creators : avatars;
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [selectedAvatarId, setSelectedAvatarId] = useState("");
  const [selectedCreatorVariantId, setSelectedCreatorVariantId] = useState("");
  const [aiBgMusicEnabled, setAiBgMusicEnabled] = useState(true);
  const { captionStyleTemplates, customTemplates } = useCaptionStyleTemplates();
  // Step: Prompt
  const [prompt, setPrompt] = useState("");
  // Persistent options
  const [savedTemplateId, setSavedTemplateId] = useLocalStorage<string>("aiAdsTemplateId");
  const [savedAvatarId, setSavedAvatarId] = useLocalStorage<string>("aiAdsAvatarId");
  const [savedCreator, setSavedCreator] = useLocalStorage<{
    id: string;
    variantId?: string;
  }>("aiCreator");
  // Generate Ads / Creator Hub
  const { startAiAdsProjectCreation, startAiCreatorsProjectCreation } =
    useContext(ProjectCreationContext);
  // Analytics
  const analytics = useAnalytics();

  const scriptType = useMemo((): ScriptType | null => {
    if (step === "url" || step === "info") {
      return null;
    }

    if (selectedScriptIndex === "custom") {
      return "custom";
    }

    return "ai_script";
  }, [step, selectedScriptIndex]);

  // this is a bit messy, but all the callbacks have nasty dependencies here
  // so by using `trackEvent` at least we get a stable reference to the function
  const _trackEventRef: React.MutableRefObject<TrackCreatorHubEventFunction> = useLatestRef(
    (eventName, params) => {
      const eventProps: TrackCreatorHubEventParams = {
        script_type: scriptType,
        language: languageCode,
        flow_type: flowType,
      };

      const pluckedProps: Record<string, unknown> = { ...eventProps, ...params };
      const normalizedProps: Record<string, unknown> = {};

      Object.keys(pluckedProps).forEach((key) => {
        if (pluckedProps[key] !== undefined) {
          normalizedProps[key] = pluckedProps[key];
        }
      });

      analytics.track(eventName, normalizedProps);
    }
  );

  const trackEvent: TrackCreatorHubEventFunction = useCallback(
    (eventName, params) => {
      _trackEventRef.current(eventName, params);
    },
    [_trackEventRef]
  );

  const infoFetcher = useAIAdsInfoFetcher({
    onFetch: (info) => {
      setTitle(info.title ?? "");
      setProductName(info.title ?? "");
      setDescription(info.productDescription ?? "");
      setTargetAudience("");
      mediaItems.setProvidedMedia(
        info.images?.map((img) => ({
          url: img.readSignedUrl,
          fileId: img.imageFileId,
        })) ?? []
      );
      setStep("info");
      setIsLoadingNextStep(false);
    },
    onError: (error) => {
      // i don't really know why this is using this event
      // TODO(DESK-1634): clean up old ai ads entrypoint
      trackEvent(AI_ADS_FORM_VIEW, {
        status: MixPanelEventStatusEnum.FAIL,
        message: error.message,
      });
      console.error("Failed to fetch ad info", error);
      let toastMessage = "Failed to get information from the URL";
      if (error.message === "Product description does not comply with our Terms of Service.") {
        toastMessage = "Please ensure your ad complies with our Terms of Use.";
      }
      toast.add(toastMessage, {
        severity: "error",
      });
      setIsLoadingNextStep(false);
    },
    onCancel: () => {
      setIsLoadingNextStep(false);
    },
  });

  const scriptsFetcher = useAIAdsScriptsFetcher({
    onFetch: (scripts, { description, languageCode }) => {
      setScripts(scripts.map((script) => ({ script, loading: false })));
      setStep("script");
      setIsLoadingNextStep(false);
      trackEvent(AI_CREATOR_SCRIPT_CREATED, {
        status: MixPanelEventStatusEnum.SUCCESS,
        prompt: description,
        language: languageCode,
        source: "generated",
      });
    },
    onError: (error) => {
      trackEvent(AI_CREATOR_SCRIPT_CREATED, {
        source: "generated",
        status: MixPanelEventStatusEnum.FAIL,
        error_code: error.code,
      });
      let toastMessage = "Failed to fetch AI ads scripts";
      if (error.message === "Product description does not comply with our Terms of Service.") {
        toastMessage = "Please ensure your ad complies with our Terms of Use.";
      }
      console.error("Failed to fetch AI ads scripts", error);
      toast.add(toastMessage, {
        severity: "error",
      });
      setIsLoadingNextStep(false);
    },
    onCancel: () => {
      setIsLoadingNextStep(false);
    },
  });

  const scriptRefresher = useAIAdsScriptsRefresher({
    onFetch: (index, script) => {
      setScripts((prevScripts) => {
        if (prevScripts[index] == null) {
          return prevScripts;
        }
        const newScripts = [...prevScripts];
        newScripts[index] = {
          script,
          loading: false,
        };
        return newScripts;
      });
    },
    onError: (index, error) => {
      console.error("Failed to refresh the ad transcript", error);
      toast.add("Failed to refresh the ad transcript", {
        severity: "error",
      });
      setScripts((prevScripts) => {
        if (prevScripts[index] == null) {
          return prevScripts;
        }
        const newScripts = [...prevScripts];
        newScripts[index] = {
          ...newScripts[index],
          loading: false,
        };
        return newScripts;
      });
    },
    onCancel: (index) => {
      setScripts((prevScripts) => {
        if (prevScripts[index] == null) {
          return prevScripts;
        }
        const newScripts = [...prevScripts];
        newScripts[index] = {
          ...newScripts[index],
          loading: false,
        };
        return newScripts;
      });
    },
  });

  const singleScriptFetcher = useCreatorHubScriptFetcher({
    onFetch: (script, { description, languageCode }) => {
      setCustomScript(script);
      setIsLoadingNextStep(false);
      trackEvent(AI_CREATOR_SCRIPT_CREATED, {
        status: MixPanelEventStatusEnum.SUCCESS,
        source: "generated",
        prompt: description,
        language: languageCode,
      });
    },
    onError: (error) => {
      trackEvent(AI_CREATOR_SCRIPT_CREATED, {
        // TODO: prompt?, description, language
        source: "generated",
        status: MixPanelEventStatusEnum.FAIL,
        error_code: error.code,
      });
      let toastMessage = "Failed to fetch AI script";
      if (error.message.includes("Content does not comply with our Terms of Service.")) {
        toastMessage = "Please ensure your prompt complies with our Terms of Use.";
      }
      console.error("Failed to fetch AI ads scripts", error);
      toast.add(toastMessage, {
        severity: "error",
      });
      setIsLoadingNextStep(false);
    },
    onCancel: () => {
      setIsLoadingNextStep(false);
    },
  });

  const onGenerateScript = (description: string) => {
    setIsLoadingNextStep(true);
    singleScriptFetcher.fetchScript({ description, languageCode: languageCode });
  };

  const open = useCallback(() => {
    // navigate using `pushState` to avoid page reload on native
    // https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#using-the-native-history-api
    const modalOpenUrl = isCreatorHubEnabled ? "/projects/generate" : "/projects/create/ads";
    window.history.pushState(null, "", modalOpenUrl);
    setProjectType(getProjectType(isCreatorHubEnabled));
    setStep(getInitialStep(isCreatorHubEnabled));
    setIsOpen(true);
  }, [isCreatorHubEnabled, router]);

  const reset = useCallback(() => {
    setStep(getInitialStep(isCreatorHubEnabled));
    setIsLoadingNextStep(false);
    setUrl("");
    setTitle("");
    setProductName("");
    setDescription("");
    setTargetAudience("");
    mediaItems.reset();
    setScripts([]);
    setPrompt("");
    setSelectedScriptIndex(0);
    setSelectedTemplateId("");
    setCustomScript("");
    setSelectedAvatarId("");
    setSelectedCreatorVariantId("");
    setAiBgMusicEnabled(true);
    // Cancel all pending requests
    infoFetcher.cancelFetchDescription();
    scriptsFetcher.cancelFetchScripts();
    scriptRefresher.cancelRefreshScripts();
  }, [mediaItems.reset, isCreatorHubEnabled]);

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsOpen(isOpen);
      if (!isOpen) {
        window.history.pushState(null, "", "/projects");
        if (step) {
          trackEvent(AI_ADS_FORM_VIEW, {
            status: MixPanelEventStatusEnum.CANCEL,
          });
        }
      }
    },
    [router, step, trackEvent]
  );

  const onBack = () => {
    setStep((prevStep) => {
      switch (prevStep) {
        case "info": {
          return "url";
        }
        case "script":
          return "info";
        case "avatar":
          setSelectedScriptIndex(0);
          return previousStep;
        case "prompt":
          setPrompt("");
          setCustomScript(undefined);
          singleScriptFetcher.cancelFetchScript();
          return "initial";
        case "custom":
          setCustomScript(undefined);
          return "initial";
        default:
          return prevStep;
      }
    });
  };

  const proceedFromUrl = useCallback(
    (params?: OnNextCallbackParams) => {
      trackEvent(AI_ADS_FORM_INPUT, {
        step: STEP_TRACKING_NAMES.url,
        url: params?.ignoreInfo ? "" : url,
      });
      if (params?.ignoreInfo) {
        infoFetcher.cancelFetchDescription();
        setTitle("");
        setProductName("");
        setDescription("");
        setTargetAudience("");
        mediaItems.reset();
        setStep("info");
        setUrl("");
        setIsLoadingNextStep(false);
        return;
      }
      infoFetcher.fetchDescription(sanitizeUrl(url));
    },
    [url]
  );

  const proceedFromInfo = useCallback(() => {
    trackEvent(AI_ADS_FORM_INPUT, {
      step: STEP_TRACKING_NAMES.info,
      product_name: productName,
      prompt: description,
      media_count: mediaItems.items.length,
      media_list: mediaItems.items.map((item) => item.fileId).join(","),
      target_audience: targetAudience,
      title: title,
    });
    scriptsFetcher.fetchScripts({ title, productName, description, targetAudience, languageCode });
  }, [title, productName, description, targetAudience, languageCode, mediaItems.items]);

  const proceedFromScript = useCallback(() => {
    if (selectedScriptIndex === "custom") {
      trackEvent(AI_ADS_FORM_INPUT, {
        step: STEP_TRACKING_NAMES.script,
        transcript: customScript,
        script_hook: "custom_script",
      });
    } else {
      const selectedScript: AIAdsScriptItem | undefined = scripts[selectedScriptIndex];
      trackEvent(AI_ADS_FORM_INPUT, {
        step: STEP_TRACKING_NAMES.script,
        transcript: selectedScript?.script.transcript ?? "",
        script_hook: selectedScript?.script.adHookCategory ?? "",
      });
    }
    // Attempts to find the last used avatar and template, using the first available ones
    // if none are found
    const savedAvatar = avatarsToUse.find((avatar) => avatar.id === savedAvatarId);
    const savedTemplate =
      customTemplates.find((template) => template.id === savedTemplateId) ??
      captionStyleTemplates?.find((template) => template.id === savedTemplateId);
    const defaultAvatarId = savedAvatar != null ? savedAvatarId : avatarsToUse[0]?.id;
    const defaultTemplateId = savedTemplate
      ? savedTemplateId
      : (captionStyleTemplates?.[0] ?? customTemplates[0])?.id;
    setSelectedAvatarId(defaultAvatarId ?? "");
    setSelectedTemplateId(defaultTemplateId ?? "");
    setStep("avatar");
    setIsLoadingNextStep(false);
  }, [
    savedAvatarId,
    savedTemplateId,
    avatarsToUse,
    customTemplates,
    captionStyleTemplates,
    scripts,
    selectedScriptIndex,
  ]);

  const proceedFromAvatar = useCallback(() => {
    const currentAvatar = avatarsToUse.find((avatar) => {
      if (selectedCreatorVariantId) {
        return avatar.id === selectedAvatarId && avatar.variantId === selectedCreatorVariantId;
      }
      return avatar.id === selectedAvatarId;
    });
    const currentTranscript =
      selectedScriptIndex === "custom"
        ? customScript
        : scripts[selectedScriptIndex].script.transcript;
    const currentTemplate =
      customTemplates.find((template) => template.id === selectedTemplateId) ??
      captionStyleTemplates?.find((template) => template.id === selectedTemplateId);
    if (!currentAvatar || !currentTranscript || !selectedTemplateId || !currentTemplate) {
      setIsLoadingNextStep(false);
      return;
    }
    trackEvent(AI_ADS_FORM_INPUT, {
      step: STEP_TRACKING_NAMES.avatar,
      avatar_id: currentAvatar.id,
      avatar_name: currentAvatar.name,
    });
    if (currentAvatar.variantId) {
      setSavedCreator({
        id: selectedAvatarId,
        variantId: selectedCreatorVariantId,
      });
    } else {
      setSavedAvatarId(selectedAvatarId);
    }
    setSavedTemplateId(selectedTemplateId);
    const smallestThumbnail = getSmallestAvatarThumbnail(currentAvatar);

    if (projectType === "ai_avatar") {
      startAiCreatorsProjectCreation({
        avatarId: currentAvatar.id,
        avatarVariantId: currentAvatar?.variantId ?? "",
        avatarName: currentAvatar.name,
        avatarType: currentAvatar.type,
        avatarThumbnailUrl: smallestThumbnail?.url,
        title: title,
        transcript: customScript ?? "",
        templateId: selectedTemplateId,
        flowType,
      });
    } else {
      const media = mediaItems.items
        .filter((item) => item.fileId && item.stage === "finished")
        .map((item) => item.fileId!);
      const mediaUris = mediaItems.items
        .filter((item) => item.url && !item.fileId)
        .map((item) => item.url!);
      startAiAdsProjectCreation({
        avatarId: currentAvatar.id,
        avatarName: currentAvatar.name,
        avatarThumbnailUrl: smallestThumbnail?.url,
        title: title || productName || prompt,
        transcript: currentTranscript,
        templateId: selectedTemplateId,
        media,
        mediaUris,
        enableBackgroundMusic: aiBgMusicEnabled,
        script_type: scriptType,
      });
    }
    setIsOpen(false);
    router.push("/projects", undefined, {
      shallow: true,
    });
    setIsLoadingNextStep(false);
  }, [
    avatarsToUse,
    customScript,
    scriptType,
    scripts,
    selectedAvatarId,
    selectedCreatorVariantId,
    selectedTemplateId,
    selectedScriptIndex,
    title,
    productName,
    mediaItems.items,
    aiBgMusicEnabled,
    customTemplates,
    captionStyleTemplates,
    startAiAdsProjectCreation,
  ]);

  const proceedFromInitial = (step: OnNextCallbackParams["nextStepFromInitial"]) => {
    if (
      step &&
      (["url", "prompt", "custom"] as OnNextCallbackParams["nextStepFromInitial"][]).includes(step)
    ) {
      setStep(step);
      setIsLoadingNextStep(false);
      setProjectType(step === "url" ? "ai_ads" : "ai_avatar");
    }
  };

  const proceedToAvatarWithCreator = () => {
    const savedCreatorData = avatarsToUse.find(
      (avatar) => avatar.id === savedCreator?.id && avatar.variantId === savedCreator?.variantId
    );
    const savedTemplate =
      customTemplates.find((template) => template.id === savedTemplateId) ??
      captionStyleTemplates?.find((template) => template.id === savedTemplateId);
    const defaultCreatorId = savedCreatorData != null ? savedCreatorData.id : avatarsToUse[0]?.id;
    const defaultCreatorVariantId = savedCreatorData?.variantId
      ? savedCreatorData.variantId
      : avatarsToUse[0]?.variantId;
    const defaultTemplateId = savedTemplate
      ? savedTemplateId
      : (captionStyleTemplates?.[0] ?? customTemplates[0])?.id;
    setSelectedAvatarId(defaultCreatorId ?? "");
    setSelectedCreatorVariantId(defaultCreatorVariantId ?? "");
    setSelectedTemplateId(defaultTemplateId ?? "");
    setStep("avatar");
  };

  const proceedFromPrompt = () => {
    setSelectedScriptIndex("custom");
    trackEvent(AI_ADS_FORM_INPUT, {
      step: STEP_TRACKING_NAMES.prompt,
      transcript: customScript,
    });

    proceedToAvatarWithCreator();
    setIsLoadingNextStep(false);
  };

  const proceedFromCustom = () => {
    trackEvent(AI_ADS_FORM_INPUT, {
      step: STEP_TRACKING_NAMES.custom,
      transcript: customScript ?? "",
    });
    setSelectedScriptIndex("custom");
    proceedToAvatarWithCreator();
    setIsLoadingNextStep(false);
  };

  const onNext = useCallback<OnNextCallback>(
    (params) => {
      setIsLoadingNextStep(true);
      setPreviousStep(step);

      // dont override with undefined
      if (params?.flowType) {
        setFlowType(params.flowType);
      }

      switch (step) {
        case "url":
          proceedFromUrl(params);
          break;
        case "info":
          proceedFromInfo();
          break;
        case "script":
          proceedFromScript();
          break;
        case "avatar":
          proceedFromAvatar();
          break;
        case "initial":
          proceedFromInitial(params?.nextStepFromInitial ?? "url");
          break;
        case "prompt":
          proceedFromPrompt();
          break;
        case "custom":
          proceedFromCustom();
          break;
      }
    },
    [step, proceedFromUrl, proceedFromScript, proceedFromInfo, proceedFromAvatar]
  );

  const onChangeUrl = useCallback((url: string) => {
    // Cancels any ongoing fetches if the user is still typing
    infoFetcher.cancelFetchDescription();
    setIsLoadingNextStep(false);
    // Sets the URL
    setUrl(url);
  }, []);

  // we only want to track this event once while the user is editing the script
  const { trigger: trackEditedEvent, reset: resetTrackEditedEvent } = useCallbackOnce(
    (source: ScriptSource) => {
      trackEvent(AI_CREATOR_SCRIPT_EDITED, {
        source,
      });
    }
  );

  // however, if they select a different script or change steps, we want to reset
  // the tracking event
  useEffect(() => {
    resetTrackEditedEvent();
  }, [step, selectedScriptIndex, resetTrackEditedEvent]);

  const onChangeScript = useCallback(
    (index: number, script: string) => {
      setScripts((prevScripts) => {
        if (prevScripts[index] == null) {
          return prevScripts;
        }
        const newScripts = [...prevScripts];
        newScripts[index] = {
          script: {
            ...newScripts[index].script,
            transcript: script,
          },
          loading: false,
        };
        return newScripts;
      });

      trackEditedEvent("generated");
    },
    [trackEditedEvent]
  );

  const onChangeCustomScript = useCallback(
    (customScript: string) => {
      setCustomScript(customScript);
      trackEditedEvent("custom");
    },
    [trackEditedEvent]
  );

  const onSelectAvatar = (avatarId: string, variantId?: string) => {
    setSelectedAvatarId(avatarId);
    if (variantId) {
      setSelectedCreatorVariantId(variantId);
    }
  };

  const handleSelectScript = (index: SelectedScriptIndex) => {
    setSelectedScriptIndex(index);
  };

  useOnChangeEffect(isOpen, (open) => {
    if (!open) {
      reset();
    }
  });

  const onRetryScript = useCallback(
    (index: number) => {
      const script = scripts[index];
      if (script == null) {
        return;
      }
      setScripts((prevScripts) => {
        if (prevScripts[index] == null) {
          return prevScripts;
        }
        const newScripts = [...prevScripts];
        newScripts[index] = {
          ...newScripts[index],
          loading: true,
        };
        return newScripts;
      });
      scriptRefresher.refreshScript(index, script.script, {
        title,
        productName,
        description,
        targetAudience,
        languageCode,
      });
    },
    [scripts, title, productName, description, targetAudience, languageCode]
  );

  useEffect(() => {
    setProjectType(getProjectType(isCreatorHubEnabled));
    setStep(getInitialStep(isCreatorHubEnabled));

    if (router.asPath.includes("/create/ads")) {
      setIsOpen(true);
    } else if (router.asPath.includes("/projects/generate")) {
      setIsOpen(true);
    }
  }, [router, isCreatorHubEnabled]);

  return {
    isCreatorHubEnabled,
    customScript,
    onChangeCustomScript,
    isOpen,
    previousStep,
    step,
    isLoadingNextStep,
    open,
    onOpenChange,
    onBack,
    onNext,
    projectType,
    flowType,
    // Step 1: URL
    url,
    onChangeUrl,
    // Step 2: Info
    title,
    productName,
    description,
    targetAudience,
    languageCode,
    availableLanguages,
    mediaItems: mediaItems.items,
    onChangeTitle: setTitle,
    onChangeProductName: setProductName,
    onChangeDescription: setDescription,
    onChangeTargetAudience: setTargetAudience,
    onChangeLanguageCode: setLanguageCode,
    onAddMediaFiles: mediaItems.addLocalFiles,
    onDeleteMediaItem: mediaItems.deleteItem,
    // Step 3: Script
    scripts,
    selectedScriptIndex,
    onSelectScript: handleSelectScript,
    onChangeScript,
    onRetryScript,
    // Step 4: Avatar
    avatars: avatarsToUse,
    availableTemplates: captionStyleTemplates ?? [],
    customTemplates,
    selectedTemplateId,
    selectedAvatarId,
    selectedCreatorVariantId,
    aiBgMusicEnabled,
    onSelectAvatar,
    onSelectTemplate: setSelectedTemplateId,
    onChangeAiBgMusicEnabled: setAiBgMusicEnabled,
    // Step: Prompt
    onGenerateScript,
    prompt,
    onChangePrompt: setPrompt,
  };
}
