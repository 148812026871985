import { AIAdsPageIndicatorContainer, AIAdsPageIndicatorStep } from "./AIAdsPageIndicator.styles";
import { AIAdsPageIndicatorProps } from "./AIAdsPageIndicator.types";

/**
 * AI Ads step indicator component.
 *
 * @param activeStep - The current active step. Goes from 1 to stepsCount.
 * @param stepsCount - The total number of steps.
 * @constructor
 */
export function AIAdsPageIndicator({ activeStep, stepsCount }: AIAdsPageIndicatorProps) {
  return (
    <AIAdsPageIndicatorContainer>
      {[...Array(stepsCount)].map((_, index) => (
        <AIAdsPageIndicatorStep key={index} isActive={activeStep === index + 1} />
      ))}
    </AIAdsPageIndicatorContainer>
  );
}
