import { styled } from "~/theme";

export const AIAdsDialogNavContainer = styled("div", {
  display: "flex",
  gap: 24,
  width: "100%",
  "&>*": {
    flex: 1,
  },
});
