import { styled } from "~/theme";

import { Textarea } from "../../Textarea";
import { AIAdsDialogField } from "../common";

export const AIAdsInfoContents = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto 1fr auto",
  columnGap: 24,
  rowGap: 16,
  width: "100%",
  flexGrow: 1,
});

export const AIAdsMediaField = styled(AIAdsDialogField, {
  gridRow: "1 / 5",
  gridColumn: "2",
});

export const AIAdsProductDescription = styled(Textarea, {
  backgroundColor: "$grey-800",
  resize: "none",
  flex: 1,
  defaultVariants: {
    fullWidth: true,
  },
});

export const AIAdsMediaContainer = styled("div", {
  width: "calc(100% + 2px)",
  flex: 1,
  display: "flex",
  overflow: "hidden",
  position: "relative",
  flexBasis: 0,
  margin: "0 -1px",
});
