import { useFeatureFlag } from "feature-flags";
import { t } from "i18next";
import { ChangeEvent, DragEvent, MouseEvent, MouseEventHandler, useRef } from "react";

import { MediaItem } from "~/hooks/useMediaItems";
import { formatFileSize } from "~/utils/file";
import { getVideoFileMetadata } from "~/utils/getVideoFileMetadata";
import { formatTimestampShorts } from "~/utils/timestamp-formatter";

import { Button } from "../Button";
import AddNoOutline from "../icons/AddNoOutline";
import Delete from "../icons/Delete";
import ErrorCircle from "../icons/ErrorCircle";
import ImportIcon from "../icons/ImportIcon";
import { ScrollArea } from "../ScrollArea";
import { Spinner } from "../Spinner";
import { Text } from "../Text";
import { useToast } from "../Toast";
import {
  ADS_MEDIA_IMAGE_SIZE_MAX,
  ADS_MEDIA_VIDEO_DURATION_MAX,
  ADS_MEDIA_VIDEO_SIZE_MAX,
  formatDuration,
  validateAdsMediaImageSize,
  validateAdsMediaVideoDuration,
  validateAdsMediaVideoSize,
} from "../VideoUpload/video-limits";

import {
  MediaInputContainer,
  MediaInputEmptyState,
  MediaInputIcon,
  MediaInputImage,
  MediaInputInput,
  MediaInputOverlay,
  MediaInputVideo,
  MediaInputVideoTimestamp,
} from "./MediaInput.styles";

const SUPPORTED_IMAGE_MIME_TYPES = [
  "image/apng",
  "image/png",
  "image/heic",
  "image/heic-sequence",
  "image/tiff",
  "image/svg+xml",
  "image/webp",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/avif",
];

const SUPPORTED_VIDEO_MIME_TYPES = ["video/*"];

interface MediaInputProps {
  mediaItems: MediaItem[];
  orientation?: "vertical" | "horizontal";
  size?: "small" | "large";
  onAddFiles?: (file: File[]) => void;
  onDeleteItem?: (item: MediaItem) => void;
  disabled?: boolean;
}

export function MediaInput({
  mediaItems,
  orientation = "horizontal",
  size = "small",
  onAddFiles,
  onDeleteItem,
  disabled,
}: MediaInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const isMediaVideoInputEnabled = useFeatureFlag("ads_media_video_input");

  const handleMediaClick: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    if (disabled) {
      return;
    }
    fileInputRef.current?.click();
  };

  const handleMediaInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    const files = Array.from(event.currentTarget.files ?? []);
    event.currentTarget.value = "";
    event.currentTarget.files = null;
    if (!files) {
      return;
    }
    const validFiles: File[] = [];
    for (const file of files) {
      if (file.type.startsWith("video")) {
        const { isValid: isVideoSizeValid } = validateAdsMediaVideoSize(file.size);
        const metadata = await getVideoFileMetadata(file);
        const { isValid: isVideoDurationValid } = validateAdsMediaVideoDuration(metadata.duration);
        if (isVideoSizeValid && isVideoDurationValid) {
          validFiles.push(file);
        }
      } else {
        const { isValid: isImageSizeValid } = validateAdsMediaImageSize(file.size);
        if (isImageSizeValid) {
          validFiles.push(file);
        }
      }
    }
    if (validFiles.length !== files.length) {
      toast.add(
        t("projects:ai-ads.description-step.media-input.toast-message", {
          videoMaxSize: formatFileSize(ADS_MEDIA_VIDEO_SIZE_MAX),
          videoMaxDuration: formatDuration(ADS_MEDIA_VIDEO_DURATION_MAX),
          imgMaxSize: formatFileSize(ADS_MEDIA_IMAGE_SIZE_MAX),
        }),
        {
          severity: "error",
          duration: 5000,
        }
      );
    }
    onAddFiles?.(validFiles);
  };

  const handleDeleteItem = (ev: MouseEvent<HTMLElement>, item: MediaItem) => {
    ev.stopPropagation();
    if (disabled) {
      return;
    }
    onDeleteItem?.(item);
  };

  const handleNonActionClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
  };

  const handleDragOver = (ev: DragEvent<HTMLDivElement>) => {
    if (!ev.dataTransfer.types.includes("Files") || disabled) {
      return;
    }
    ev.dataTransfer.dropEffect = "copy";
    ev.preventDefault();
    ev.stopPropagation();
  };

  const handleDrop = (ev: DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    const files = Array.from(ev.dataTransfer.files ?? []);
    if (!files || disabled) {
      return;
    }
    onAddFiles?.(files);
  };

  const iconSize = size === "large" ? 32 : 24;
  const iconSizeProps = {
    width: iconSize,
    height: iconSize,
  };

  const supportedMimeTypes = isMediaVideoInputEnabled
    ? SUPPORTED_IMAGE_MIME_TYPES.concat(SUPPORTED_VIDEO_MIME_TYPES)
    : SUPPORTED_IMAGE_MIME_TYPES;

  const mediaInputFieldElement = (
    <MediaInputInput
      ref={fileInputRef}
      onChange={handleMediaInputChange}
      type="file"
      multiple
      accept={supportedMimeTypes.join(", ")}
    />
  );

  if (mediaItems.length === 0) {
    return (
      <MediaInputEmptyState
        orientation={orientation}
        onClick={handleMediaClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <ImportIcon {...iconSizeProps} />
        <Text color="grey-500" variant="body-1">
          {orientation === "horizontal" &&
            t("projects:ai-ads.description-step.media-input.drop-or-upload")}
          {orientation === "vertical" &&
            t("projects:ai-ads.description-step.media-input.drop-or-import")}
        </Text>
        {mediaInputFieldElement}
      </MediaInputEmptyState>
    );
  }

  return (
    <ScrollArea
      orientation={orientation}
      fillRow={orientation === "horizontal"}
      fillColumn={orientation === "vertical"}
      enableFade
      hideScrollBars
    >
      <MediaInputContainer
        orientation={orientation}
        size={size}
        onClick={handleMediaClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <MediaInputIcon
          size={size}
          isAddItems
          onClick={handleMediaClick}
          isDisabled={Boolean(disabled)}
        >
          {size === "small" && <AddNoOutline width={16} height={16} />}
          {size === "large" && (
            <>
              <ImportIcon {...iconSizeProps} />
              <Text variant="body-2" color="inherit">
                {t("projects:ai-ads.description-step.media-input.import")}
              </Text>
            </>
          )}
        </MediaInputIcon>
        {...mediaItems.map((m, i) => {
          const state =
            m.stage === "to-upload" || m.stage === "uploading"
              ? "loading"
              : m.stage === "error"
              ? "error"
              : "finished";
          const clickHandler = (ev: MouseEvent<HTMLElement>) => handleDeleteItem(ev, m);
          return (
            <MediaInputIcon
              key={i}
              size={size}
              onClick={size === "small" ? clickHandler : handleNonActionClick}
            >
              {state === "loading" && <Spinner theme="white" inline size="sm" />}
              {state === "error" && <ErrorCircle {...iconSizeProps} />}
              {state === "finished" &&
                (m.fileResponse?.fileMetadata?.mimeType?.startsWith("video") ? (
                  <>
                    <MediaInputVideo controls={false} muted>
                      <source src={m.providedMedia?.url ?? m.fileResponse?.url} />
                    </MediaInputVideo>
                    {m.fileResponse?.fileMetadata?.duration && (
                      <MediaInputVideoTimestamp as="time" color="grey-100" variant="body-3">
                        {formatTimestampShorts(m.fileResponse.fileMetadata.duration)}
                      </MediaInputVideoTimestamp>
                    )}
                  </>
                ) : (
                  <MediaInputImage
                    alt={m.fileResponse?.fileMetadata?.filename ?? ""}
                    src={m.providedMedia?.url ?? m.fileResponse?.url}
                  />
                ))}

              {!disabled && (
                <MediaInputOverlay size={size}>
                  {size === "small" && <Delete {...iconSizeProps} />}
                  {size === "large" && (
                    <Button
                      variant="primary"
                      colorScheme="grey"
                      size="sm"
                      onlyIcon
                      onClick={clickHandler}
                    >
                      <Delete />
                    </Button>
                  )}
                </MediaInputOverlay>
              )}
            </MediaInputIcon>
          );
        })}
        {mediaInputFieldElement}
      </MediaInputContainer>
    </ScrollArea>
  );
}
