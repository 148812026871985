import { styled } from "~/theme";

import { BaseDialogContent } from "../BaseDialog";

export const AIAdsDialogContents = styled(BaseDialogContent, {
  gap: "24px !important",
  maxWidth: "90vw !important",
  maxHeight: "90vh !important",
  width: "822px !important",
  height: "949px !important",
  variants: {
    step: {
      url: {
        gap: "$16 !important",
        padding: "$24 0 !important",
        width: "885px !important",
        height: "766px !important",
        overflow: "hidden",
      },
      products: {
        padding: "$48 !important",
      },
      info: {
        padding: "$48 !important",
      },
      script: {
        padding: "$48 !important",
      },
      avatar: {
        padding: "$48 $48 $24 $48 !important",
      },
      initial: {},
      prompt: {},
      custom: {},
    },
  },
});
