import { styled } from "@stitches/react";

import { Text } from "../Text";

export const MediaInputContainer = styled("div", {
  height: "100%",
  width: "fit-content",
  minWidth: "100%",
  display: "flex",
  flexDirection: "row",
  variants: {
    orientation: {
      vertical: {
        flexWrap: "wrap",
      },
      horizontal: {},
    },
    size: {
      small: {
        padding: "9px",
        gap: "6px",
      },
      large: {
        rowGap: 16,
        columnGap: 12,
      },
    },
  },
});

export const MediaInputEmptyState = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  cursor: "pointer",
  variants: {
    orientation: {
      vertical: {
        flexDirection: "column",
        color: "$grey-500",
        gap: 8,
        border: "2px dashed $grey-700",
        borderRadius: "12px",
      },
      horizontal: {
        flexDirection: "row",
        color: "$primary-purple",
        gap: "10px",
      },
    },
  },
});

export const MediaInputInput = styled("input", {
  display: "none",
});

export const MediaInputIcon = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  margin: 0,
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  contain: "content",
  color: "$grey-700",
  variants: {
    size: {
      small: {
        height: "60px",
        width: "60px",
        minWidth: "60px",
        borderRadius: "6px",
        "&::after": {
          position: "absolute",
          content: '""',
          width: "100%",
          height: "100%",
          borderRadius: "6px",
          pointerEvents: "none",
          border: "1.5px solid $colors$surface-highlight",
        },
      },
      large: {
        height: "170px",
        width: "170px",
        minWidth: "170px",
        borderRadius: 12,
        gap: 8,
        border: "4px solid $colors$grey-800",
      },
    },
    isAddItems: {
      true: {},
    },
    isDisabled: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isAddItems: true,
      isDisabled: true,
      css: {
        cursor: "not-allowed",
      },
    },
    {
      isAddItems: true,
      isDisabled: false,
      css: {
        cursor: "pointer",
      },
    },
    {
      size: "large",
      isAddItems: true,
      css: {
        border: "2px dashed $colors$grey-700",
        color: "$grey-500",
      },
    },
  ],
  // The following properties are necessary to prevent a visual glitch where the borders of the
  // image show through the overlay
  perspective: "1000",
  backfaceVisibility: "hidden",
  transform: "translate3d(0,0,0)",
});

export const MediaInputImage = styled("img", {
  objectFit: "cover",
  position: "absolute",
  inset: 0,
  width: "100%",
  height: "100%",
  // The following properties are necessary to prevent a visual glitch where the borders of the
  // image show through the overlay
  borderRadius: "6px",
  perspective: "1000",
  backfaceVisibility: "hidden",
  transform: "translate3d(0,0,0)",
});

export const MediaInputVideoTimestamp = styled(Text, {
  backgroundColor: "$surface-overlay",
  display: "flex",
  alignItems: "center",
  borderRadius: 6,
  lineHeight: 1,
  bottom: 12,
  right: 12,
  position: "absolute",
  padding: "4px 8px",
});

export const MediaInputVideo = styled("video", {
  objectFit: "cover",
  position: "absolute",
  inset: 0,
  width: "100%",
  height: "100%",
  borderRadius: "6px",
  perspective: "1000",
  backfaceVisibility: "hidden",
  transform: "translate3d(0,0,0)",
});

export const MediaInputOverlay = styled("div", {
  position: "absolute",
  inset: 0,
  background: "transparent",
  display: "flex",
  color: "$grey-100",
  "&:not(:hover)": {
    opacity: 0,
  },
  variants: {
    size: {
      small: {
        "&:hover": {
          background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) -90%, rgba(0, 0, 0, 0.97) 100%)",
        },
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "center",
      },
      large: {
        "&:hover": {
          background:
            "linear-gradient(41deg, rgba(0, 0, 0, 0.00) 63.06%, rgba(0, 0, 0, 0.60) 99.95%)",
        },
        justifyContent: "flex-end",
        padding: 8,
      },
    },
  },
});
