import { styled } from "~/theme";

import AIAds from "../../icons/AIAds";
import PersonAI from "../../icons/PersonAI";

const HEADER_URL_WRAPPER_HEIGHT = "264px";

export const AIAdsBannerSection = styled("div", {
  height: "100%",
  maxHeight: `calc(100% - ${HEADER_URL_WRAPPER_HEIGHT})`,
  position: "relative",
});

export const AIAdsBannerPicture = styled("picture", {
  "& > img": {
    height: "100%",
  },
});

export const AIAdsBannerBlurOverlayWrapper = styled("div", {
  position: "absolute",
  height: "100%",
  width: "100%",
});

export const blueLightGradient =
  "radial-gradient(23.06% 31.06% at 19.51% 33.27%, rgba(35, 123, 255, 0.5) 0%, rgba(38, 125, 255, 0) 100%), #06145E";

export const AIAdsBannerBlurOverlay = styled("div", {
  position: "relative",
  left: "15%",
  height: "100%",
  width: "70%",
  background: blueLightGradient,
  opacity: "0.5",
  filter: "blur(130px)",
  borderRadius: "50%",
});

export const AIAdsDialogHeaderUrlWrapper = styled("div", {
  height: HEADER_URL_WRAPPER_HEIGHT,
  zIndex: "1",
  padding: "0 $56",
  display: "flex",
  width: "100%",
  justifyContent: "center",
});

export const AIAdsDialogHeaderUrlContainer = styled("div", {
  width: "100%",
  maxWidth: "352px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 16,
});

export const AIAdsIcon = styled(AIAds, {
  color: "$grey-500",
});

export const AIAdsUrlRightSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 36,
  height: 36,
  // This frame is closer to the edges than the DS control allows, so compensate the paddings
  // with negative margins.
  margin: "-6px -12px -6px 0",
});

export const AIAdsUrlDivider = styled("div", {
  display: "flex",
  alignItems: "center",
  fontSize: "0.9375rem",
  letterSpacing: "0.1em",
  width: "100%",

  "&::before": {
    content: "",
    height: "1px",
    backgroundColor: "$grey-800",
    flexGrow: 1,
    marginRight: "1rem",
  },

  "&::after": {
    content: "",
    height: "1px",
    backgroundColor: "$grey-800",
    flexGrow: 1,
    marginLeft: "1rem",
  },
});
