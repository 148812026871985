import { t } from "i18next";
import { useMemo } from "react";

import { getLanguageOptionSearchScore } from "~/modules/project/components/LanguageSelector";
import { LanguageItemLabel } from "~/modules/project/components/LanguageSelector/LanguageItemLabel";
import {
  getLocalizedLanguageLabel,
  getLocalizedLanguageName,
} from "~/modules/project/utils/getLocalizedLanguageName";

import { ComboBox, ComboBoxGroup, ComboBoxOption } from "../../ComboBox";
import { MediaInput } from "../../MediaInput";
import { TextInput } from "../../TextInput";
import {
  AIAdsDialogField,
  AIAdsDialogFieldTitle,
  AIAdsDialogHeader,
  AIAdsDialogNav,
  AIAdsDialogSubtitle,
  AIAdsDialogTitle,
  AIAdsPageIndicator,
} from "../common";

import {
  AIAdsInfoContents,
  AIAdsMediaContainer,
  AIAdsMediaField,
  AIAdsProductDescription,
} from "./AIAdsDescriptionStep.styles";
import { AIAdsDescriptionStepProps } from "./AIAdsDescriptionStep.types";

export function AIAdsDescriptionStep({
  isLoadingNextStep,
  title,
  productName,
  description,
  targetAudience,
  languageCode,
  availableLanguages,
  mediaItems,
  onChangeTitle,
  onChangeProductName,
  onChangeDescription,
  onChangeTargetAudience,
  onChangeLanguageCode,
  onAddMediaFiles,
  onDeleteMediaItem,
  onNext,
  onBack,
}: AIAdsDescriptionStepProps) {
  const isAnyMediaUploading = mediaItems.some((item) =>
    ["to-upload", "uploading"].includes(item.stage)
  );

  const isNextDisabled =
    productName.length === 0 || description.length === 0 || isAnyMediaUploading;

  const isAvailableLanguageListLoading = isLoadingNextStep || availableLanguages.length === 0;

  const normalizedLanguageOptions: (ComboBoxOption | ComboBoxGroup)[] = useMemo(() => {
    return [
      {
        label: t("common:language-selector.options.translation-languages"),
        options: availableLanguages
          .filter((item) => item.code !== languageCode)
          .map((item) => ({
            label: (
              <LanguageItemLabel
                displayName={item.display}
                localizedDisplayName={getLocalizedLanguageName(item)}
              />
            ),
            value: item.code,
            getSearchScore: (searchQuery) => getLanguageOptionSearchScore(item, searchQuery),
          })),
      },
    ];
  }, [languageCode, availableLanguages]);

  const customValue = (value?: string) => {
    const language = availableLanguages.find((item) => item.code === value);

    // Should never happen; fallback to default Radix Select behavior
    if (!language) {
      return undefined;
    }

    return getLocalizedLanguageLabel(language);
  };

  return (
    <>
      <AIAdsDialogHeader>
        <AIAdsDialogTitle
          title={title}
          onChangeTitle={onChangeTitle}
          placeholder={t("projects:ai-ads.description-step.title-placeholder")}
          readOnly={isLoadingNextStep}
        />
        <AIAdsDialogSubtitle>{t("projects:ai-ads.description-step.subtitle")}</AIAdsDialogSubtitle>
      </AIAdsDialogHeader>
      <AIAdsPageIndicator activeStep={1} stepsCount={3} />
      <AIAdsInfoContents>
        <AIAdsDialogField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.description-step.product-name")}
          </AIAdsDialogFieldTitle>
          <TextInput
            value={productName}
            placeholder={t("projects:ai-ads.description-step.product-name-placeholder")}
            fullWidth
            variant="subfield-comfortable"
            disabled={isLoadingNextStep}
            onChange={(ev) => onChangeProductName(ev.target.value)}
          />
        </AIAdsDialogField>
        <AIAdsDialogField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.description-step.description")}
          </AIAdsDialogFieldTitle>
          <AIAdsProductDescription
            value={description}
            placeholder={t("projects:ai-ads.description-step.description-placeholder")}
            onChange={(ev) => onChangeDescription(ev.target.value)}
            disabled={isLoadingNextStep}
          />
        </AIAdsDialogField>
        <AIAdsDialogField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.description-step.language")}
          </AIAdsDialogFieldTitle>
          <ComboBox
            options={normalizedLanguageOptions}
            disabled={isAvailableLanguageListLoading}
            value={languageCode}
            customValue={customValue}
            onValueChange={(value) => onChangeLanguageCode(value)}
            searchPlaceholder={t("common:language-selector.search-placeholder")}
            placeholder={t("common:language-selector.placeholder")}
          />
        </AIAdsDialogField>
        <AIAdsDialogField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.description-step.target-audience")}
          </AIAdsDialogFieldTitle>
          <TextInput
            value={targetAudience}
            placeholder={t("projects:ai-ads.description-step.target-audience-placeholder")}
            fullWidth
            variant="subfield-comfortable"
            disabled={isLoadingNextStep}
            onChange={(ev) => onChangeTargetAudience(ev.target.value)}
          />
        </AIAdsDialogField>
        <AIAdsMediaField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.description-step.media")}
          </AIAdsDialogFieldTitle>
          <AIAdsMediaContainer>
            <MediaInput
              mediaItems={mediaItems}
              onAddFiles={onAddMediaFiles}
              onDeleteItem={onDeleteMediaItem}
              orientation="vertical"
              size="large"
              disabled={isLoadingNextStep}
            />
          </AIAdsMediaContainer>
        </AIAdsMediaField>
      </AIAdsInfoContents>
      <AIAdsDialogNav
        onNext={onNext}
        onBack={onBack}
        isNextDisabled={isNextDisabled}
        isLoadingNextStep={isLoadingNextStep}
        nextLabel={t("projects:ai-ads.description-step.select-your-script")}
      />
    </>
  );
}
