import type { IconProps } from "./types";

function Music({ height = "24", width = "24", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.9999 17.6804V6.04615C18.9999 4.19072 17.3326 2.78017 15.5028 3.08763L9.84565 4.0382C8.40089 4.28097 7.34277 5.53171 7.34277 6.99673V19.1495"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <ellipse
        cx="4.67143"
        cy="19.3451"
        rx="2.67143"
        ry="2.15464"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <ellipse
        cx="16.3287"
        cy="17.3871"
        rx="2.67143"
        ry="2.15464"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M7.34277 9.84548L18.5142 7.88672" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}

export default Music;
