import { styled } from "~/theme";

import { Text } from "../../Text";

export const AIAdsDialogTitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  width: "100%",
  height: "2em",
  gap: 8,
  color: "$grey-500",
  "&>*": {
    flexShrink: 0,
  },
  "&:hover": {
    color: "$grey-200",
  },
  variants: {
    hasValue: {
      true: {
        color: "$grey-200",
      },
    },
  },
});

export const AIAdsDialogTitleLabel = styled(Text, {
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: 0,
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: "fit-content",
  defaultVariants: {
    color: "inherit",
    variant: "heading-3",
  },
});

export const AIAdsDialogTitleInput = styled("input", {
  width: "100%",
  fontFamily: "$avenir-next",
  fontSize: "$24",
  fontWeight: "$demi-bold",
  lineHeight: "$32",
  letterSpacing: "$spacing-1",
  color: "$grey-200",
  margin: "0",
  border: "none",
  outline: "none",
  background: "transparent",
  textAlign: "center",
});
