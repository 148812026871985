import {
  ProductSource,
  ScriptType,
} from "~/context/ProjectCreationContext/ProjectCreationContext.types";
import { MediaItem } from "~/hooks/useMediaItems";
import { CaptionTemplateWithDates } from "~/modules/project/hooks/useCaptionStyleTemplates";
import { AvailableLanguageOption } from "~/modules/project/services/AvailableTranscriptLanguages";
import { CaptionTemplate } from "~/modules/project/services/CaptionStylePreset";
import { AIAdsScript } from "~/modules/project/services/UGCAds/getAIAdScripts";
import { Avatar } from "~/modules/project/services/UGCAds/UGCAdGetAvatars";

export type CreatorHubFlowType = "ai_creator" | "ai_ad" | "script";

export type AIAdsCreationStepV2 = "url" | "info" | "script" | "avatar";

export type CreatorHubStep = "initial" | "prompt" | "custom";

export type AIAdsCreationStep = AIAdsCreationStepV2 | CreatorHubStep;

export interface AIAdsScriptItem {
  script: AIAdsScript;
  loading: boolean;
}

export type OnNextCallbackParams = {
  ignoreInfo?: boolean;
  nextStepFromInitial?: Extract<AIAdsCreationStep, "url" | "prompt" | "custom">;
  flowType?: CreatorHubFlowType;
};

export type OnNextCallback = (params?: OnNextCallbackParams) => void;
export type SelectedScriptIndex = number | "custom";

export type GeneratedVideoProjectType = "ai_ads" | "ai_avatar";

export interface AIAdsCreationDialogProps {
  isOpen: boolean;
  previousStep?: AIAdsCreationStep;
  step?: AIAdsCreationStep;
  isLoadingNextStep: boolean;
  customScript: string | undefined;
  onChangeCustomScript: (customScript: string) => void;
  onOpenChange: (isOpen: boolean) => void;
  onBack: () => void;
  /**
   * Goes to the next step.
   * @param {boolean} [ignoreInfo] - only valid for the first step, if true, it will ignore the
   * given URL.
   */
  onNext: OnNextCallback;
  // Step 1: URL
  url: string;
  onChangeUrl: (url: string) => void;
  // Step 2: Info
  title: string;
  productName: string;
  description: string;
  targetAudience: string;
  languageCode: string;
  availableLanguages: AvailableLanguageOption[];
  mediaItems: MediaItem[];
  onChangeTitle: (title: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeProductName: (productName: string) => void;
  onChangeTargetAudience: (targetAudience: string) => void;
  onChangeLanguageCode: (languageCode: string) => void;
  onAddMediaFiles?: (file: File[]) => void;
  onDeleteMediaItem?: (item: MediaItem) => void;
  // Step 3: Script
  scripts: AIAdsScriptItem[];
  selectedScriptIndex: SelectedScriptIndex;
  onSelectScript: (index: SelectedScriptIndex) => void;
  onChangeScript: (index: number, script: string) => void;
  onRetryScript: (index: number) => void;
  // Step 4: Avatar
  avatars: Avatar[];
  availableTemplates: CaptionTemplate[];
  customTemplates: CaptionTemplateWithDates[];
  selectedTemplateId: string;
  selectedAvatarId: string;
  selectedCreatorVariantId: string;
  aiBgMusicEnabled: boolean;
  onSelectAvatar: (avatarId: string) => void;
  onSelectTemplate: (templateId: string) => void;
  onChangeAiBgMusicEnabled: (enabled: boolean) => void;
  // Step: Prompt
  onGenerateScript: (prompt: string) => void;
  prompt: string;
  onChangePrompt: (prompt: string) => void;
  projectType?: GeneratedVideoProjectType;
  flowType?: CreatorHubFlowType;
}

export enum MixPanelEventStatusEnum {
  CANCEL = "cancel",
  FAIL = "fail",
  SUCCESS = "success",
  ERROR = "error",
  FINISH = "finish",
  OFF = "off",
  ON = "on",
  START = "start",
  RETRY = "retry",
}

export type ScriptSource = "generated" | "custom";

export interface TrackCreatorHubEventParams {
  status?: MixPanelEventStatusEnum;
  script_type?: ScriptType | null;
  url?: string | null;
  step?: string | null;
  source?: ScriptSource | null;
  script_hook?: AIAdsScript["adHookCategory"] | string | null;
  transcript?: AIAdsScript["transcript"] | string | null;
  title?: string | null;
  avatar_id?: Avatar["id"] | null;
  avatar_name?: Avatar["name"] | null;
  product_name?: string | null;
  prompt?: string | null;
  media_count?: number | null;
  media_list?: string | null;
  target_audience?: string | null;
  message?: string | null;
  error_code?: string | null;
  language?: string;
  flow_type?: CreatorHubFlowType;
}

export type TrackCreatorHubEventFunction = (
  eventName: string,
  params: TrackCreatorHubEventParams
) => void;
