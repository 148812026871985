import { t } from "i18next";
import { useCallback, useMemo } from "react";

import { AI_CREATORS_TERMS_LINK } from "~/constants/links.contants";
import { AI_CREATOR_CLICK, TEMPLATE_CLICK } from "~/constants/mixpanel.constants";
import { useAnalytics } from "~/hooks/useAnalytics";
import { Avatar } from "~/modules/project/services/UGCAds/UGCAdGetAvatars";
import { getSmallestAvatarThumbnail } from "~/modules/project/utils/avatarThumbnail";
import { template_click } from "~/utils/analytics/ProductEvents";

import { AIAvatarItem, AVATAR_COLORS } from "../../AIAvatarItem";
import Music from "../../icons/Music";
import { ScrollArea } from "../../ScrollArea";
import Switch from "../../Switch/Switch";
import {
  AIAdsDialogField,
  AIAdsDialogFieldTitle,
  AIAdsDialogHeader,
  AIAdsDialogNav,
  AIAdsDialogSubtitle,
  AIAdsDialogTitle,
  AIAdsPageIndicator,
} from "../common";

import {
  AIAdsAvatars,
  AIAdsFooter,
  AIAdsFooterLink,
  AIAdsFooterText,
  AIAdsStyleContents,
  AIAdsTemplateList,
} from "./AIAdsAvatarStep.styles";
import { AIAdsAvatarStepProps } from "./AIAdsAvatarStep.types";

export function AIAdsAvatarStep({
  projectType,
  flowType,
  hasBackgroundMusicOption = true,
  isLoadingNextStep,
  avatars,
  availableTemplates,
  customTemplates,
  selectedTemplateId,
  selectedAvatarId,
  selectedCreatorVariantId,
  aiBgMusicEnabled,
  previousStep,
  onSelectAvatar,
  onSelectTemplate,
  onChangeAiBgMusicEnabled,
  onNext,
  onBack,
}: AIAdsAvatarStepProps) {
  const isNextDisabled = useMemo(
    () =>
      selectedTemplateId.length === 0 ||
      selectedAvatarId.length === 0 ||
      avatars.find((item) =>
        item?.variantId
          ? item.id === selectedAvatarId && item.variantId === selectedCreatorVariantId
          : item.id === selectedAvatarId
      ) == null ||
      (availableTemplates.find((item) => item.id === selectedTemplateId) == null &&
        customTemplates.find((item) => item.id === selectedTemplateId) == null),
    [
      selectedTemplateId,
      selectedAvatarId,
      avatars,
      availableTemplates,
      customTemplates,
      selectedCreatorVariantId,
    ]
  );

  const isSpecialStep = ["prompt", "custom"].includes(previousStep ?? "");
  const stepsCount = isSpecialStep ? 2 : 3;

  const cta =
    projectType === "ai_ads"
      ? t("projects:ai-ads.avatar-step.generate-ads")
      : t("projects:ai-ads.avatar-step.generate-video");

  const analytics = useAnalytics();

  const onTemplateChange = useCallback(
    (id: string) => {
      onSelectTemplate(id);

      const newPreset = availableTemplates.find((preset) => preset.id === id);

      analytics.track(
        ...template_click({
          flow_type: flowType,
          style_id: id,
          style_name: newPreset?.displayName,
        })
      );
    },
    [onSelectTemplate, availableTemplates, analytics]
  );

  const onAvatarClick = useCallback(
    (a: Avatar) => {
      onSelectAvatar(a.id, a?.variantId);

      analytics.track(AI_CREATOR_CLICK, {
        creator_id: a.id,
        creator_name: a.name,
        creator_type: a.type,
        flow_type: flowType,
      });
    },
    [onSelectAvatar, analytics, flowType]
  );

  return (
    <>
      <AIAdsDialogHeader>
        <AIAdsDialogTitle title={t("projects:ai-ads.avatar-step.title")} readOnly={true} />
        <AIAdsDialogSubtitle>{t("projects:ai-ads.avatar-step.subtitle")}</AIAdsDialogSubtitle>
      </AIAdsDialogHeader>
      <AIAdsPageIndicator activeStep={stepsCount} stepsCount={stepsCount} />
      <AIAdsStyleContents>
        <AIAdsDialogField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.avatar-step.select-creator")}
          </AIAdsDialogFieldTitle>
          <ScrollArea fillColumn enableFade hideScrollBars>
            <AIAdsAvatars>
              {avatars.map((a, i) => (
                <AIAvatarItem
                  key={i}
                  id={a.id}
                  type={a.type}
                  selected={
                    a?.variantId
                      ? selectedAvatarId === a.id && selectedCreatorVariantId === a.variantId
                      : selectedAvatarId === a.id
                  }
                  onClick={() => onAvatarClick(a)}
                  previewUrl={a.previewUrl}
                  posterUrl={getSmallestAvatarThumbnail(a)?.url}
                  name={a.name}
                  color={AVATAR_COLORS[i % AVATAR_COLORS.length]}
                  colorScheme="niagara"
                />
              ))}
            </AIAdsAvatars>
          </ScrollArea>
        </AIAdsDialogField>
        <AIAdsDialogField>
          <AIAdsDialogFieldTitle>
            {t("projects:ai-ads.avatar-step.captions-template.title")}
          </AIAdsDialogFieldTitle>
          <ScrollArea fillColumn enableFade hideScrollBars>
            <AIAdsTemplateList
              availableTemplates={availableTemplates}
              customTemplates={customTemplates}
              countryCode={"en-US"}
              selectedStyleId={selectedTemplateId}
              onTemplateChange={onTemplateChange}
              colorScheme="niagara"
              condensed
            />
          </ScrollArea>
          {hasBackgroundMusicOption && (
            <Switch
              colorScheme="niagara"
              label={t("projects:ai-ads.avatar-step.captions-template.background-music.label")}
              description={t(
                "projects:ai-ads.avatar-step.captions-template.background-music.description"
              )}
              icon={<Music />}
              checked={aiBgMusicEnabled}
              onCheckedChange={onChangeAiBgMusicEnabled}
            />
          )}
        </AIAdsDialogField>
      </AIAdsStyleContents>
      <AIAdsDialogNav
        onNext={onNext}
        onBack={onBack}
        isNextDisabled={isNextDisabled}
        isLoadingNextStep={isLoadingNextStep}
        nextLabel={cta}
      />
      <AIAdsFooter>
        <AIAdsFooterText>
          {t("projects:ai-ads.footer.text", {
            content: cta,
          })}
        </AIAdsFooterText>
        <AIAdsFooterLink as="a" href={AI_CREATORS_TERMS_LINK} target="blank">
          {t("projects:ai-ads.footer.link")}
        </AIAdsFooterLink>
        <AIAdsFooterText>{t("projects:ai-ads.footer.want-to-be-creator")}</AIAdsFooterText>
        <AIAdsFooterLink as="a" href="https://captions.typeform.com/to/bsi5qlEh" target="blank">
          {t("projects:ai-ads.footer.contact-us")}
        </AIAdsFooterLink>
      </AIAdsFooter>
    </>
  );
}
