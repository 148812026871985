import { ComponentProps } from "react";

import { CounterDisplay } from "./CharacterCounter.styles";

interface Props extends ComponentProps<typeof CounterDisplay> {
  maxLength: number;
  value: string;
}

export function CharacterCounter({ maxLength, value, ...props }: Props) {
  return (
    <CounterDisplay hasError={value.length > maxLength} {...props}>
      {value.length} / {maxLength}
    </CounterDisplay>
  );
}
