import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { GetUploadedFileUrlResponse } from "~/services/UploadFile/UploadFile.dto";

import { useMultipleFileUpload } from "./useMultipleFileUpload";

type MediaToUseStage = "to-upload" | "uploading" | "finished" | "error";

export interface ProvidedMedia {
  url: string;
  fileId: string | null;
}

export interface MediaItem {
  stage: MediaToUseStage;
  file?: File | null;
  fileResponse?: GetUploadedFileUrlResponse | null;
  providedMedia?: ProvidedMedia | null;
  operationId?: string;
  fileId?: string | null;
  url?: string;
}

export function useMediaItems() {
  const [items, setItems] = useState<MediaItem[]>([]);

  const { cancelUpload, cancelAllUploads, startUpload } = useMultipleFileUpload({
    onStartUpload: (operationId) => {
      setItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.operationId === operationId ? { ...prevItem, stage: "uploading" } : prevItem
        )
      );
    },
    onCancelUpload: (operationId) => {
      setItems((prevItems) => prevItems.filter((prevItem) => prevItem.operationId !== operationId));
    },
    onError: (operationId, errorMessage) => {
      // TODO: Toast? Analytics?
      console.error(errorMessage);
      setItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.operationId === operationId ? { ...prevItem, stage: "error" } : prevItem
        )
      );
    },
    onFinishUpload: (operationId, uploadedFile) => {
      setItems((prevItems) =>
        prevItems.map((prevItem) =>
          prevItem.operationId === operationId
            ? {
                ...prevItem,
                stage: "finished",
                fileResponse: uploadedFile,
                file: null,
                fileId: uploadedFile.fileId,
              }
            : prevItem
        )
      );
    },
  });

  /**
   * Adds the provided files to the list of media items and starts the upload process for each file.
   * @param {File[]} files - The files to add.
   * @returns {Promise<void>} A promise that resolves when all upload processes.
   * have started.
   */
  const addLocalFiles = useCallback(async (files: File[]) => {
    const newItems = files.map<MediaItem>((file) => ({
      stage: "to-upload",
      file,
      fileResponse: null,
      operationId: uuidv4(),
      fileId: null,
    }));
    setItems((prevItems) => [...prevItems, ...newItems]);
    await Promise.all(newItems.map((item) => startUpload(item.operationId!, item.file!)));
  }, []);

  /**
   * Deletes the provided media item from the list of media items, canceling any upload processes
   * if necessary.
   * @param {MediaItem} item - The media item to delete.
   * @returns {void}
   */
  const deleteItem = useCallback((item: MediaItem) => {
    if (["uploading", "to-upload"].includes(item.stage)) {
      cancelUpload(item.operationId!);
    }
    setItems((prevItems) =>
      prevItems.filter((prevItem) =>
        item.operationId ? prevItem.operationId !== item.operationId : prevItem !== item
      )
    );
  }, []);

  /**
   * Overrides the current media items with the provided media returned from the backend.
   * @remarks Will use up to 5 items
   * @param {ProvidedMedia[]} providedMedia - The provided media items to set.
   * @returns {void}
   */
  const setProvidedMedia = useCallback(
    (providedMedia: ProvidedMedia[]) => {
      setItems(
        providedMedia.slice(0, 5).map((m) => ({
          stage: "finished",
          fileResponse: null,
          providedMedia: m,
          fileId: m.fileId || null,
          url: m.url,
        }))
      );
      cancelAllUploads();
    },
    [cancelAllUploads]
  );

  /**
   * Resets the media items and cancels all upload processes.
   * @returns {void}
   */
  const reset = useCallback(() => {
    setItems([]);
    cancelAllUploads();
  }, [cancelAllUploads]);

  // Ensures all upload processes are cancelled when the component unmounts
  useEffect(() => {
    return () => {
      cancelAllUploads();
    };
  }, []);

  return {
    items,
    reset,
    addLocalFiles,
    deleteItem,
    setProvidedMedia,
  };
}
