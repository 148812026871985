import { AxiosError } from "axios";

import { BackendServicesClient } from "~/context/BackendServicesContext";

type GetAIScriptSuccessResponse = {
  success: true;
  data: {
    script: string;
  };
};

type GetAIScriptErrorResponse = {
  success: false;
  reason: string;
};

type GetAIScriptResponse = GetAIScriptSuccessResponse | GetAIScriptErrorResponse;

export async function getAIScript(
  client: BackendServicesClient,
  adData: {
    description: string;
    languageCode: string;
  },
  options: { signal?: AbortSignal } = {}
): Promise<string> {
  try {
    const { data } = await client.post<GetAIScriptResponse>(
      "proxy/v1/ai-avatar/script",
      {
        topic: adData.description,
        languageCode: adData.languageCode,
      },
      { signal: options.signal }
    );

    if (!data.success) {
      throw new AxiosError(data.reason);
    }

    return data.data.script;
  } catch (error) {
    if (!options.signal?.aborted) {
      console.error(error);
    }
    throw error;
  }
}
