import { styled } from "~/theme";

export const CounterDisplay = styled("p", {
  fontSize: "$12",
  color: "$grey-500",
  margin: 0,
  padding: 0,
  textAlign: "right",
  fontVariantNumeric: "tabular-nums",
  variants: {
    hasError: {
      true: {
        color: "$secondary-error",
      },
    },
  },
});
