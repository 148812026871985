import { t, exists } from "i18next";
import { ChangeEvent, FocusEvent, MouseEvent, UIEvent } from "react";

import { Button } from "../../Button";
import { Spinner } from "../../Spinner";

import {
  AIAdsHookName,
  AIAdsItemTranscript,
  AIAdsRefreshIcon,
  AIAdsScriptItemContainer,
  AIAdsScriptItemHeader,
} from "./AIAdsScriptItem.styles";
import { AIAdsScriptItemProps } from "./AIAdsScriptItem.types";

export function AIAdsScriptItem({
  item,
  isSelected,
  onRetry,
  onSelect,
  onEdit,
}: AIAdsScriptItemProps) {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.currentTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    onSelect?.();
  };

  const handleFocus = (event: FocusEvent<HTMLElement>) => {
    event.currentTarget.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    onSelect?.();
  };

  const handleRetry = (event: MouseEvent<HTMLElement>) => {
    onRetry?.();
    event.stopPropagation();
  };

  const stopPropagation = (event: UIEvent<HTMLElement> | FocusEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onEdit?.(event.target.value);
  };

  return (
    <AIAdsScriptItemContainer isSelected={isSelected} onClick={handleClick} onFocus={handleFocus}>
      <AIAdsScriptItemHeader>
        <AIAdsHookName>{getItemHookTitle(item)}</AIAdsHookName>
        <Button
          variant="tertiary"
          colorScheme="niagara"
          size="tn"
          onlyIcon
          onClick={handleRetry}
          onPointerDown={stopPropagation}
          onPointerUp={stopPropagation}
          onFocus={stopPropagation}
        >
          <AIAdsRefreshIcon isRefreshing={item.loading} />
        </Button>
      </AIAdsScriptItemHeader>
      <AIAdsItemTranscript
        as="textarea"
        maxLength={500}
        value={item.script.transcript}
        style={item.loading ? { visibility: "hidden" } : undefined}
        onChange={handleChange}
      />
      {item.loading && <Spinner theme="white" />}
    </AIAdsScriptItemContainer>
  );
}

function getItemHookTitle(item: AIAdsScriptItemProps["item"]): string | null {
  if (!item.script.adHookCategory) {
    return null;
  }
  const strKey = `projects:ai-ads.ad-hooks.${item.script.adHookCategory.id}`;
  if (exists(strKey)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return t(strKey);
  }
  return item.script.adHookCategory.name;
}
