import { Root } from "@radix-ui/react-dialog";
import { useCallback } from "react";

import { CloseButton } from "../BaseDialog";

import { AIAdsAvatarStep } from "./AIAdsAvatarStep";
import { AIAdsDialogContents } from "./AIAdsCreationDialog.styles";
import { AIAdsCreationDialogProps } from "./AIAdsCreationDialog.types";
import { AIAdsDescriptionStep } from "./AIAdsDescriptionStep";
import { AIAdsScriptStep } from "./AIAdsScriptStep";
import { AIAdsUrlStep } from "./AIAdsUrlStep";

export function AIAdsCreationDialog({
  customScript,
  flowType,
  previousStep,
  step,
  isOpen,
  onOpenChange,
  onNext,
  onBack,
  isLoadingNextStep,
  // Step 1
  url,
  onChangeUrl,
  // Step 2
  title,
  productName,
  description,
  targetAudience,
  languageCode,
  availableLanguages,
  mediaItems,
  onChangeCustomScript,
  onChangeTitle,
  onChangeProductName,
  onChangeDescription,
  onChangeTargetAudience,
  onChangeLanguageCode,
  onAddMediaFiles,
  onDeleteMediaItem,
  // Step 3
  scripts,
  selectedScriptIndex,
  onSelectScript,
  onChangeScript,
  onRetryScript,
  // Step 4
  avatars,
  availableTemplates,
  customTemplates,
  selectedTemplateId,
  selectedAvatarId,
  selectedCreatorVariantId,
  aiBgMusicEnabled,
  onSelectAvatar,
  onSelectTemplate,
  onChangeAiBgMusicEnabled,
}: AIAdsCreationDialogProps) {
  const handleClose = useCallback(() => {
    onOpenChange?.(false);
  }, []);

  return (
    <Root open={isOpen}>
      <AIAdsDialogContents step={step}>
        {step === "url" && (
          <AIAdsUrlStep
            isLoadingNextStep={isLoadingNextStep}
            url={url}
            onNext={onNext}
            onChangeUrl={onChangeUrl}
          />
        )}
        {step === "info" && (
          <AIAdsDescriptionStep
            isLoadingNextStep={isLoadingNextStep}
            onNext={onNext}
            onBack={onBack}
            title={title}
            productName={productName}
            description={description}
            targetAudience={targetAudience}
            languageCode={languageCode}
            availableLanguages={availableLanguages}
            mediaItems={mediaItems}
            onChangeTitle={onChangeTitle}
            onChangeProductName={onChangeProductName}
            onChangeDescription={onChangeDescription}
            onChangeTargetAudience={onChangeTargetAudience}
            onChangeLanguageCode={onChangeLanguageCode}
            onAddMediaFiles={onAddMediaFiles}
            onDeleteMediaItem={onDeleteMediaItem}
          />
        )}
        {step === "script" && (
          <AIAdsScriptStep
            customScript={customScript}
            onChangeCustomScript={onChangeCustomScript}
            isLoadingNextStep={isLoadingNextStep}
            onNext={onNext}
            onBack={onBack}
            scripts={scripts}
            selectedScriptIndex={selectedScriptIndex}
            onSelectScript={onSelectScript}
            onChangeScript={onChangeScript}
            onRetryScript={onRetryScript}
          />
        )}
        {step === "avatar" && (
          <AIAdsAvatarStep
            projectType="ai_ads"
            flowType={flowType}
            previousStep={previousStep}
            isLoadingNextStep={isLoadingNextStep}
            onNext={onNext}
            onBack={onBack}
            avatars={avatars}
            availableTemplates={availableTemplates}
            customTemplates={customTemplates}
            selectedTemplateId={selectedTemplateId}
            selectedAvatarId={selectedAvatarId}
            selectedCreatorVariantId={selectedCreatorVariantId}
            aiBgMusicEnabled={aiBgMusicEnabled}
            onSelectAvatar={onSelectAvatar}
            onSelectTemplate={onSelectTemplate}
            onChangeAiBgMusicEnabled={onChangeAiBgMusicEnabled}
          />
        )}
        <CloseButton onClick={handleClose} />
      </AIAdsDialogContents>
    </Root>
  );
}
