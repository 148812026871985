import useLatest from "@react-hook/latest";
import { t } from "i18next";
import { ChangeEvent, FormEvent, KeyboardEvent, useCallback, useMemo, useRef } from "react";

import { AI_ADS_FORM_VIEW } from "~/constants/mixpanel.constants";
import { useTrackEventOnMount } from "~/hooks/useAnalytics";
import { isValidUrl } from "~/utils/url";

import { Button } from "../../Button";
import ArrowRight from "../../icons/ArrowRight";
import Link from "../../icons/Link";
import { Spinner } from "../../Spinner";
import { Text } from "../../Text";
import { TextInput } from "../../TextInput";
import { AIAdsDialogHeader, AIAdsDialogSubtitle, AIAdsDialogTitle } from "../common";

import {
  AIAdsBannerBlurOverlay,
  AIAdsBannerBlurOverlayWrapper,
  AIAdsBannerPicture,
  AIAdsBannerSection,
  AIAdsDialogHeaderUrlContainer,
  AIAdsDialogHeaderUrlWrapper,
  AIAdsUrlDivider,
  AIAdsUrlRightSection,
} from "./AIAdsUrlStep.styles";
import { AIAdsUrlStepProps } from "./AIAdsUrlStep.types";

const URL_PROCEED_DELAY = 50;

const ADS_BANNER_PNG_SRCSET = [
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-1x.png 1x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-1.5x.png 1.5x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-2x.png 2x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-3x.png 3x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-4x.png 4x",
].join(", ");

const ADS_BANNER_WEBP_SRCSET = [
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-1x.webp 1x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-1.5x.webp 1.5x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-2x.webp 2x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-3x.webp 3x",
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-4x.webp 4x",
].join(", ");

const ADS_BANNER_SRC =
  "https://captions-cdn.xyz/desktop-site/ads-onboarding-banner-assets-v2/ads-onboarding-banner-2x.png";

export function AIAdsUrlStep({
  url,
  onChangeUrl,
  isLoadingNextStep,
  onNext,
  isCreatorHub,
}: AIAdsUrlStepProps) {
  // whether from the creator hub or directly from home,
  // track the event once this view is shown
  useTrackEventOnMount(AI_ADS_FORM_VIEW);

  const applyUrlTimeout = useRef<number | null>(null);
  const isNextEnabled = useMemo(() => url.length > 0 && isValidUrl(url), [url]);
  const urlHasError = useMemo(() => url.length > 0 && !isValidUrl(url), [url]);

  // Use latest is necessary to prevent stale onNext from being called inside the timeout
  // callback. This is because onNext is a callback that can change between renders.
  const onNextLatest = useLatest(onNext);

  const handleInputUrl = useCallback((event: FormEvent<HTMLInputElement>) => {
    const inputEvent = event.nativeEvent as InputEvent;
    if (applyUrlTimeout.current) {
      window.clearTimeout(applyUrlTimeout.current);
      applyUrlTimeout.current = null;
    }
    if (inputEvent.inputType && inputEvent.inputType.startsWith("insertFromPaste")) {
      applyUrlTimeout.current = window.setTimeout(() => {
        applyUrlTimeout.current = null;
        onNextLatest.current({ ignoreInfo: false });
      }, URL_PROCEED_DELAY);
    }
  }, []);

  const handleChangeUrl = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeUrl(event.target.value);
  }, []);

  const clearApplyUrlTimeout = () => {
    if (applyUrlTimeout.current) {
      window.clearTimeout(applyUrlTimeout.current);
      applyUrlTimeout.current = null;
    }
  };

  const handleNextManual = useCallback(() => {
    clearApplyUrlTimeout();
    onNextLatest.current({ ignoreInfo: true });
  }, []);

  const handleNextClick = useCallback(() => {
    clearApplyUrlTimeout();
    onNextLatest.current({ ignoreInfo: false });
  }, []);

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === "Enter" && isNextEnabled) {
        ev.preventDefault();
        ev.stopPropagation();
        handleNextClick();
      }
    },
    [isNextEnabled]
  );

  return (
    <>
      <AIAdsBannerSection>
        <AIAdsBannerBlurOverlayWrapper>
          <AIAdsBannerBlurOverlay />
        </AIAdsBannerBlurOverlayWrapper>

        <AIAdsBannerPicture>
          <source type="image/webp" srcSet={ADS_BANNER_WEBP_SRCSET} />
          <source type="image/png" srcSet={ADS_BANNER_PNG_SRCSET} />
          <img src={ADS_BANNER_SRC} alt="Ads Onboarding Banner" draggable={false} />
        </AIAdsBannerPicture>
      </AIAdsBannerSection>

      <AIAdsDialogHeaderUrlWrapper>
        <AIAdsDialogHeaderUrlContainer css={{ gap: isCreatorHub ? 10 : 16 }}>
          <AIAdsDialogHeader>
            <AIAdsDialogTitle title={t("projects:ai-ads.title")} readOnly />
            <AIAdsDialogSubtitle>
              {isCreatorHub
                ? t("projects:ai-ads.subtitle-creator-hub")
                : t("projects:ai-ads.subtitle")}
            </AIAdsDialogSubtitle>
          </AIAdsDialogHeader>
          <TextInput
            value={url}
            type="url"
            spellCheck={false}
            fullWidth
            icon={<Link />}
            variant="subfield-comfortable"
            hasError={urlHasError}
            onInput={handleInputUrl}
            onChange={handleChangeUrl}
            onKeyDown={handleKeyDown}
            placeholder={t("projects:ai-ads.product-link-placeholder")}
          >
            <AIAdsUrlRightSection>
              {isLoadingNextStep && <Spinner theme="white" inline />}
              {!isLoadingNextStep && (
                <Button
                  onlyIcon
                  size="sm"
                  colorScheme="niagara"
                  disabled={!isNextEnabled}
                  onClick={handleNextClick}
                >
                  <ArrowRight />
                </Button>
              )}
            </AIAdsUrlRightSection>
          </TextInput>
          {isCreatorHub && (
            <AIAdsUrlDivider>
              <Text variant="body-1" color="grey-600">
                or
              </Text>
            </AIAdsUrlDivider>
          )}
          <Button
            variant="tertiary"
            colorScheme="niagara"
            disabled={isLoadingNextStep}
            onClick={handleNextManual}
          >
            {t("projects:ai-ads.create-manually")}
          </Button>
        </AIAdsDialogHeaderUrlContainer>
      </AIAdsDialogHeaderUrlWrapper>
    </>
  );
}
