import { styled } from "~/theme";

function EditBare({ ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7554 18.2731L18.9586 10.0698C19.6254 9.40302 20 8.49863 20 7.55562C20 6.61261 19.6254 5.70822 18.9586 5.04141C17.57 3.65286 15.3188 3.65286 13.9303 5.04141L5.72697 13.2446C5.04697 13.9246 4.60587 14.8068 4.46987 15.7588L4.00894 18.9854C3.96938 19.2624 4.06252 19.5418 4.26036 19.7396C4.4582 19.9375 4.73763 20.0306 5.0146 19.9911L8.24114 19.5301C9.19315 19.3942 10.0754 18.9531 10.7554 18.2731Z"
        stroke="#CED0D7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9999 7L16.9999 11"
        stroke="#CED0D7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(EditBare, {
  color: "$grey-200",
  height: "24px",
  width: "24px",
});
