import useLatest from "@react-hook/latest";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { getAIScript } from "~/modules/project/services/AIAvatar/getAIScript";

interface CreatorHubScriptFetcherSubmitParams {
  description: string;
  languageCode: string;
}

export interface CreatorHubScriptFetcherProps {
  /**
   * Callback to be called when the script is fetched.
   * @param {AIAdsScript} script - The fetched script.
   */
  onFetch: (script: string, params: CreatorHubScriptFetcherSubmitParams) => void;
  /**
   * Callback to be called when an error occurs while fetching the script.
   * @param {AxiosError} error
   */
  onError: (error: AxiosError, params: CreatorHubScriptFetcherSubmitParams) => void;
  /**
   * Callback to be called when the fetch is cancelled.
   * @remarks Will not be called if a request is cancelled due to a new request being made.
   * @remarks Will not be called if a request is cancelled due to the component unmounting.
   */
  onCancel: () => void;
}

export function useCreatorHubScriptFetcher(callbacks: CreatorHubScriptFetcherProps) {
  const client = useBackendServicesClient();
  const abortController = useRef<AbortController | null>(null);

  const onFetch = useLatest(callbacks.onFetch);
  const onError = useLatest(callbacks.onError);
  const onCancel = useLatest(callbacks.onCancel);

  const fetchScript = useCallback((params: CreatorHubScriptFetcherSubmitParams) => {
    const fetchAbortController = new AbortController();
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = fetchAbortController;
    getAIScript(client, params, { signal: fetchAbortController.signal })
      .then((script) => {
        onFetch.current(script, params);
      })
      .catch((error) => {
        if (fetchAbortController.signal.aborted) {
          return;
        }
        let err: AxiosError;
        if (error instanceof AxiosError) {
          err = new AxiosError(error.message || "Failed to fetch AI script");
        } else {
          err = new AxiosError("Failed to fetch AI script");
        }
        onError.current(err, params);
      })
      .finally(() => {
        if (abortController.current === fetchAbortController) {
          abortController.current = null;
        }
      });
  }, []);

  const cancelFetchScript = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
      onCancel.current();
      abortController.current = null;
    }
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return {
    fetchScript,
    cancelFetchScript,
  };
}
