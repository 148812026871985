import * as Tabs from "@radix-ui/react-tabs";

import { styled } from "~/theme";

export const Trigger = styled(Tabs.Trigger, {
  border: "1px solid transparent",
  borderRadius: "$6",
  color: "$grey-100",
  fontFamily: "$avenir-next",
  fontSize: "$18",
  fontWeight: "$medium",
  height: "100%",
  letterSpacing: "$spacing-1",
  width: "100%",
  defaultVariants: {
    variant: "primary",
  },
  variants: {
    variant: {
      primary: {
        "&[data-state=active]": {
          backgroundColor: "$primary-purple",
          borderColor: "$primary-purple",
        },
      },
      secondary: {
        "&[data-state=active]": {
          backgroundColor: "$grey-800",
          borderColor: "$grey-800",
        },
      },
      custom: {},
    },
  },
});

export const List = styled(Tabs.List, {
  borderRadius: "$8",
  background: "$grey-900",
  border: "1px solid $grey-800",
  display: "flex",
  height: "40px",
});
