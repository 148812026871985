import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";

import {
  AIAdsDialogTitleContainer,
  AIAdsDialogTitleInput,
  AIAdsDialogTitleLabel,
} from "./AIAdsDialogTitle.styles";
import { AIAdsDialogTitleProps } from "./AIAdsDialogTitle.types";

export function AIAdsDialogTitle({
  title,
  readOnly,
  onChangeTitle,
  placeholder,
}: AIAdsDialogTitleProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeTitle?.(event.target.value);
    },
    [onChangeTitle]
  );

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Escape"].includes(event.key)) {
      event.currentTarget.blur();
    }
  }, []);

  const handleBlur = useCallback(() => {
    setEditing(false);
  }, []);

  useEffect(() => {
    if (readOnly) {
      setEditing(false);
    }
  }, [readOnly]);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [editing]);

  return (
    <AIAdsDialogTitleContainer hasValue={title.length > 0} onClick={() => setEditing(!readOnly)}>
      {editing && (
        <AIAdsDialogTitleInput
          ref={inputRef}
          value={title}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          translate="no"
        />
      )}
      {!editing && (
        <>
          <AIAdsDialogTitleLabel translate="no">{title || placeholder}</AIAdsDialogTitleLabel>
        </>
      )}
    </AIAdsDialogTitleContainer>
  );
}
