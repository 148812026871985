import useLatest from "@react-hook/latest";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import {
  AIAdDescription,
  getAIAdDescription,
} from "~/modules/project/services/UGCAds/getAIAdDescription";

export interface AIAdsInfoFetcherProps {
  /**
   * Callback to be called when the ad description is fetched.
   * @param {AIAdDescription} info - The fetched ad description.
   */
  onFetch: (info: AIAdDescription) => void;
  /**
   * Callback to be called when an error occurs while fetching the ad description.
   * @param {AxiosError} error
   */
  onError: (error: AxiosError) => void;
  /**
   * Callback to be called when the fetch is cancelled.
   * @remarks Will not be called if a request is cancelled due to a new request being made.
   * @remarks Will not be called if a request is cancelled due to the component unmounting.
   */
  onCancel: () => void;
}

export function useAIAdsInfoFetcher(callbacks: AIAdsInfoFetcherProps) {
  const client = useBackendServicesClient();
  const abortController = useRef<AbortController | null>(null);

  const onFetch = useLatest(callbacks.onFetch);
  const onError = useLatest(callbacks.onError);
  const onCancel = useLatest(callbacks.onCancel);

  const fetchDescription = useCallback((url: string) => {
    const fetchAbortController = new AbortController();
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = fetchAbortController;
    getAIAdDescription(client, url, { signal: fetchAbortController.signal })
      .then((description) => {
        onFetch.current(description);
      })
      .catch((error) => {
        if (fetchAbortController.signal.aborted) {
          return;
        }
        const err = new AxiosError(error.response.data.message || "Failed to fetch ad description");
        onError.current(err);
      })
      .finally(() => {
        if (abortController.current === fetchAbortController) {
          abortController.current = null;
        }
      });
  }, []);

  const cancelFetchDescription = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
      onCancel.current();
    }
    abortController.current = null;
  }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  return { fetchDescription, cancelFetchDescription };
}
