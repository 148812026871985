import { styled } from "~/theme";

import { Tabs } from "../../Tabs";
import { Textarea } from "../../Textarea";

export const AiAdsTabsRoot = styled(Tabs.Root, {
  width: "100%",
});

export const AiAdsTabsList = styled(Tabs.List, {
  minHeight: "40px",
  width: "fit-content",
});

export const AiAdsTabsTrigger = styled(Tabs.Trigger, {
  padding: "0 20px",
  whiteSpace: "nowrap",
});

export const AIAdsScriptStepContents = styled("div", {
  display: "grid",
  gridAutoRows: "248px",
  gridTemplateColumns: "1fr 1fr",
  gap: 8,
});

export const CustomScriptContainer = styled("div", {
  width: "100%",
  height: "100%",
  paddingBottom: "$8",
});

export const AiAdsCustomScriptTextArea = styled(Textarea, {
  backgroundColor: "$grey-800",
  height: "100%",
  resize: "none",
  defaultVariants: {
    fullWidth: true,
  },
});
