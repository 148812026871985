import { useFeatureFlag } from "feature-flags";
import { t } from "i18next";
import { useState } from "react";

import { CharacterCounter } from "../../CharacterCounter";
import { ScrollArea } from "../../ScrollArea";
import { Tabs } from "../../Tabs";
import {
  AIAdsDialogHeader,
  AIAdsDialogNav,
  AIAdsDialogSubtitle,
  AIAdsDialogTitle,
  AIAdsPageIndicator,
} from "../common";
import { AI_ADS_SCRIPT_MAX_LENGTH } from "../useAIAdsCreationDialog";

import { AIAdsScriptItem } from "./AIAdsScriptItem";
import {
  AIAdsScriptStepContents,
  AiAdsTabsList,
  AiAdsTabsTrigger,
  AiAdsTabsRoot,
  AiAdsCustomScriptTextArea,
  CustomScriptContainer,
} from "./AIAdsScriptStep.styles";
import { AIAdsScriptStepProps, AiAdsScriptStepTabId } from "./AIAdsScriptStep.types";

export function AIAdsScriptStep({
  customScript,
  isLoadingNextStep,
  scripts,
  selectedScriptIndex,
  onChangeCustomScript,
  onSelectScript,
  onChangeScript,
  onRetryScript,
  onNext,
  onBack,
}: AIAdsScriptStepProps) {
  const [selectedTabId, setSelectedTabId] = useState<AiAdsScriptStepTabId>("ai-scripts");
  const customScriptEnabled = useFeatureFlag("ads_custom_script");

  const selectedScript = selectedScriptIndex === "custom" ? null : scripts[selectedScriptIndex];
  const isNextDisabled =
    (selectedTabId === "ai-scripts" && !selectedScript?.script.transcript) ||
    (selectedTabId === "custom-scripts" && !customScript) ||
    scripts.length === 0 ||
    (selectedScriptIndex !== "custom" && selectedScriptIndex < 0) ||
    (selectedScriptIndex !== "custom" && scripts[selectedScriptIndex].loading);

  const handleTabChange = (value: AiAdsScriptStepTabId) => {
    if (value === "custom-scripts") {
      onSelectScript("custom");
    } else {
      onSelectScript(0);
    }
    setSelectedTabId(value);
  };

  return (
    <>
      <AIAdsDialogHeader>
        <AIAdsDialogTitle title={t("projects:ai-ads.script-step.title")} readOnly={true} />
        <AIAdsDialogSubtitle>{t("projects:ai-ads.script-step.subtitle")}</AIAdsDialogSubtitle>
      </AIAdsDialogHeader>
      <AIAdsPageIndicator activeStep={2} stepsCount={3} />

      <AiAdsTabsRoot
        asChild
        onValueChange={(value) => handleTabChange(value as AiAdsScriptStepTabId)}
        value={selectedTabId}
      >
        <>
          {customScriptEnabled && (
            <AiAdsTabsList>
              <AiAdsTabsTrigger variant="secondary" value="ai-scripts">
                {t("projects:ai-ads.script-step.tabs.ai-scripts")}
              </AiAdsTabsTrigger>
              <AiAdsTabsTrigger variant="secondary" value="custom-scripts">
                {t("projects:ai-ads.script-step.tabs.custom")}
              </AiAdsTabsTrigger>
            </AiAdsTabsList>
          )}
          <ScrollArea fillColumn hideScrollBars enableFade>
            <Tabs.Content value="ai-scripts">
              <AIAdsScriptStepContents>
                {scripts.map((script, index) => (
                  <AIAdsScriptItem
                    key={index}
                    item={script}
                    isSelected={index === selectedScriptIndex}
                    onSelect={() => onSelectScript(index)}
                    onEdit={(newScript) => onChangeScript(index, newScript)}
                    onRetry={() => onRetryScript(index)}
                  />
                ))}
              </AIAdsScriptStepContents>
            </Tabs.Content>
          </ScrollArea>
          {customScriptEnabled && (
            <Tabs.Content asChild value="custom-scripts">
              <CustomScriptContainer>
                <AiAdsCustomScriptTextArea
                  maxLength={AI_ADS_SCRIPT_MAX_LENGTH}
                  placeholder={t("projects:ai-ads.script-step.tabs.custom-prompt-placeholder")}
                  onChange={(e) => onChangeCustomScript(e.target.value)}
                  value={customScript}
                />
                <CharacterCounter maxLength={AI_ADS_SCRIPT_MAX_LENGTH} value={customScript ?? ""} />
              </CustomScriptContainer>
            </Tabs.Content>
          )}
        </>
      </AiAdsTabsRoot>
      <AIAdsDialogNav
        onNext={onNext}
        onBack={onBack}
        isNextDisabled={isNextDisabled}
        isLoadingNextStep={isLoadingNextStep}
        nextLabel={t("projects:ai-ads.script-step.next-step-label")}
      />
    </>
  );
}
