import { keyframes, styled } from "~/theme";

import { Refresh } from "../../icons/Refresh";
import { Text } from "../../Text";

export const AIAdsScriptItemContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  gap: 12,
  padding: 16,
  border: "2px solid $colors$grey-900",
  borderRadius: 8,
  cursor: "pointer",
  backgroundColor: "$grey-800",
  "&:hover": {
    backgroundColor: "$grey-800-highlighted",
  },
  variants: {
    isSelected: {
      true: {
        borderColor: "$secondary-niagara",
      },
    },
  },
});

export const AIAdsScriptItemHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 12,
});

export const AIAdsHookName = styled(Text, {
  defaultVariants: {
    variant: "body-2-bold",
    color: "grey-100",
  },
});

const rotateClockwise = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(-360deg)",
  },
});

export const AIAdsRefreshIcon = styled(Refresh, {
  color: "$grey-500",
  variants: {
    isRefreshing: {
      true: {
        animation: `${rotateClockwise} 1s ease 1`,
      },
      false: {},
    },
  },
});

export const AIAdsItemTranscript = styled(Text, {
  resize: "none",
  width: "100%",
  flexGrow: 1,
  margin: 0,
  padding: 0,
  border: "none",
  backgroundColor: "transparent",
  outline: "none",
  defaultVariants: {
    variant: "body-2",
    color: "grey-300",
  },
});
