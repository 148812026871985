import type { IconProps } from "./types";

export function Refresh(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.04883 12.8889C4.01656 12.597 4 12.3005 4 12C4 7.58172 7.58172 4 12 4C13.3624 4 14.6452 4.34055 15.768 4.94118M19.8018 10.2222C19.9315 10.794 20 11.389 20 12C20 16.4183 16.4183 20 12 20C10.6376 20 9.35477 19.6594 8.23195 19.0588"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.25 11L4.25 14L7.25 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.75 12L19.75 9L22.75 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
